import { styled } from "../../theme";
import { Media, MediaImage } from "../../types";

export const SubMenuOverlay = styled.div<{ overlayColor: string }>`
  z-index: 8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ overlayColor }) => `${overlayColor}`};
  opacity: 0.6;
  animation: fadeIn linear 0.2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

export const SubMenuStyle = styled.div<{ bg: MediaImage | string }>`
  width: 100%;
  min-width: 100%;
  height: 100%;
  flex-grow: 1;
  background: ${({ bg }) => (bg ? (typeof bg === "string" ? bg : `url("${bg.url}")`) : "none")};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    flex-direction: column;
    justify-content: center;
    height: initial;
    min-height: 100%;
  }
`;
export const SubMenuSectionStyle = styled.div`
  padding: 1em;
  box-sizing: border-box;
  max-width: 70%;
  display: flex;
  justify-content: flex-end;
  z-index: 9;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
`;
export const SubMenuContainerStyle = styled.div<{ bg: Media | string; autoWidth: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 2em;
  width: ${({ autoWidth }) => `${autoWidth}px`};
  background: ${({ bg }) => (bg ? (typeof bg === "string" ? bg : `url("${bg.url}")`) : "none")};
  background-size: cover;
  min-height: 100%;
  max-height: 100%;
  border-radius: 2em;
  position: relative;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    width: 100%;
  }
`;
export const SubMenuTitleContainerStyle = styled.div`
  font-size: 1.5em;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
  }
`;
export const SubMenuObjectiveContainerStyle = styled.div`
  box-sizing: border-box;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
  }
`;
export const SubMenuObjectiveTextStyle = styled.p`
  box-sizing: border-box;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
  }
`;
export const SubMenuBoxItemContainerStyle = styled.div`
  box-sizing: border-box;
  min-height: 50%;
  height: 100%;
  max-height: 60%;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    max-height: initial;
    min-height: 100%;
  }
`;
export const CloseButtonContainer = styled.div`
  top: 1em;
  right: 1em;
  position: absolute;
  z-index: 10;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    top: -0.5em;
    right: -0.5em;
  }
`;
