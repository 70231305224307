import { styled, theme } from "../../theme";

const renderIcon = (type: string) => {
  if (type === "pdf") {
    return theme.icons.resourcePdfFile;
  }
  if (type === "hyperlink") {
    return theme.icons.resourceHyperLink;
  } else {
    return theme.icons.resourceDefaultIcon;
  }
};

export const ResourceItemContainer = styled.a`
  box-sizing: border-box;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100%;
  height: 100%;
  color: ${(props) => props.color || props.theme.color.text};
  text-decoration: none;
`;
export const ResourceIconContainer = styled.div`
  width: 10%;
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

export const ResourceIcon = styled.div<{ resourceType: string }>`
  width: 2.5em;
  height: 2.5em;
  background-image: ${({ resourceType }) => `url(${renderIcon(resourceType)})`};
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${(props) => props.theme.color.primary};
  display: block;
  border-radius: 2em;
`;
export const ResourceInfoContainer = styled.div`
  box-sizing: border-box;
  width: 93%;
  min-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ResourceName = styled.div`
  box-sizing: border-box;

  font-weight: bold;
`;

export const ResourceDescription = styled.div`
  box-sizing: border-box;
`;
