import React, { useContext, useState, useEffect } from "react";
import { Footer, Header, MainMenu, SubMenu, ActivityList, StandardCloseButton } from "./components";
import { AppStyle, MainContainer, ContainerInModal } from "./App.style";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { StoreContext } from "./context";
import ReactModal from "react-modal";
import { useWindowSize } from "./utils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const App = () => {
  const query = useQuery();
  const windowDimensions = useWindowSize();
  const [modalContent, setModalContent] = useState<string | undefined>();

  const { store, core } = useContext(StoreContext);
  const [boxWidth, setBoxWidth] = useState(0);

  useEffect(() => {
    const shouldOpenModal = async () => {
      if (store.isFirstTime && store.content.welcomeMessage) setModalContent(store.content.welcomeMessage);
    };
    setTimeout(() => {
      void shouldOpenModal();
    }, 1000);
  }, []);

  // useEffect(() => {
  //   console.log(windowDimensions);
  // }, [windowDimensions]);

  if (query.get("idBox")) {
    const box = core.getBox(query.get("idBox") as string, store.content.boxList);
    core.setCurrentBox(box);
  } else {
    core.setCurrentBox(undefined);
  }
  const setHeaderTitleWidth = (boxWidth: number) => {
    setBoxWidth(boxWidth);
  };

  useEffect(() => {
    return core.close;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppStyle className="app-container">
        <Header
          hidden={store.isFullScreen}
          autoWidth={boxWidth}
          logo={{ type: "image", url: store.content.logo }}
          title={store.content.title}
        />
        <MainContainer className="main-container" fullscreen={store.isFullScreen}>
          <Switch>
            <Route
              path="/activity-list"
              component={() => {
                if (store.currentBox && store.currentBox.type === "content")
                  return (
                    <ActivityList
                      activityList={store.currentBox.activityList}
                      background={store.currentBox.background}
                    />
                  );
                return <div>Nope</div>;
              }}
            />
            <Route
              path="/box"
              component={() => {
                if (store.currentBox && store.currentBox.type === "menu")
                  return <SubMenu bg={store.currentBox.background} parentBox={store.currentBox} />;
                return <Redirect to={"/"} />;
              }}
            />
            <Route
              path="/"
              component={() => (
                <MainMenu
                  bg={store.content.backgroundMedia}
                  onChangeBoxListContainerWidth={setHeaderTitleWidth}
                  boxList={store.content.boxList}
                />
              )}
            />
          </Switch>
        </MainContainer>
        <Footer
          hidden={store.isFullScreen}
          leftItemList={store.content.footerLeft}
          rightItemList={store.content.footerRight}
          footerBackground={store.content.footerBackground}
        />
        <ReactModal
          style={{
            overlay: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 10,
            },
            content: {
              position: "unset",
              maxWidth: "70vw",
              maxHeight: "90vh",
            },
          }}
          isOpen={modalContent !== undefined}
          onRequestClose={() => setModalContent(undefined)}
        >
          <ContainerInModal>
            <StandardCloseButton style={{ alignSelf: "flex-end" }} onClick={() => setModalContent(undefined)} />
            <div
              style={{ paddingRight: "3em", paddingLeft: "3em" }}
              dangerouslySetInnerHTML={{ __html: modalContent || "" }}
            />
          </ContainerInModal>
        </ReactModal>
      </AppStyle>
    </ThemeProvider>
  );
};

export default observer(App);
