import React, { useContext, useEffect } from "react";
import { ActivityQuizResult } from "../../types";
import {
  Container,
  ColumnContainer,
  Column,
  ScoreText,
  ScoreNumber,
  ScoreUnit,
  ScoreContainer,
  QuizResultTextContainer,
  CertificateTitleContainer,
  CertificateContainer,
  QuizResultButtonContainer,
  QuizResultTitleContainer,
} from "./ActivityQuizResult.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import texts from "../../texts";
import Certificate from "../Certificate/CertificateInput.component";
import { Title, StandardButton } from "..";
import { StoreContext } from "../../context";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

interface Props extends BaseActivityProps {
  activity: ActivityQuizResult;
}

const formatScoreUnit = ({
  quizLength,
  scoreExpression,
}: {
  scoreExpression: "percentage" | "questionLenght";
  quizLength: number;
}) => (scoreExpression === "percentage" ? "%" : `/${quizLength}`);

const ActivityQuizResultComponent: React.FC<Props> = (props) => {
  const { core, store } = useContext(StoreContext);
  const success = store.quizResults[props.activity.relatedQuiz].successStatus === "passed";
  const score = store.quizResults[props.activity.relatedQuiz].numberOfRightAnswers;
  const history = useHistory();

  useEffect(() => {
    core.setInteractionResult(props.activity.relatedQuiz, success ? "correct" : "incorrect");
  }, []);

  const reDoQuiz = () => {
    const idBox = store.currentBox?.id;
    if (idBox) {
      history.push(`/activity-list?idBox=${idBox}`);
    }
  };
  
  const onSubmitIdentity = (identity: { firstName: string; lastName: string }) => {
    if (props.activity.shouldSendDataTo) {
      void core.sendSCORMStatsToServer({ url: props.activity.shouldSendDataTo, identity });
    }
  };

  return (
    <Container>
      {props.activity.title && (
        <QuizResultTitleContainer className="quiz-result-title-container">
          <Title level={2}>{props.activity.title}</Title>
        </QuizResultTitleContainer>
      )}
      <ColumnContainer className={"result-container"} reverse={false}>
        <Column className="column-result-content" fullWidth={!props.activity.success.certificate || !success}>
          <ScoreContainer className="score-container">
            <Title customClass="score-title" level={2}>
              <ScoreText className="score-text">{`${texts.score} `}</ScoreText>
              <ScoreNumber className="score-number">
                {props.activity.scoreExpression === "questionLenght"
                  ? score
                  : Math.round((score / store.quizResults[props.activity.relatedQuiz].quiz.questionList.length) * 100)}
              </ScoreNumber>
              <ScoreUnit className="score-unit">
                {formatScoreUnit({
                  quizLength: store.quizResults[props.activity.relatedQuiz].quiz.questionList.length,
                  scoreExpression: props.activity.scoreExpression,
                })}
              </ScoreUnit>
            </Title>
          </ScoreContainer>
          <QuizResultTextContainer className="result-text-container">
            <div
              className="result-text"
              dangerouslySetInnerHTML={{
                __html: success ? props.activity.success.text : props.activity.fail.text,
              }}
            />
          </QuizResultTextContainer>
          {!success && (
            <QuizResultButtonContainer className="result-button-container">
              <StandardButton onClick={props.onClickClose}>{texts.backToHome}</StandardButton>
              <StandardButton onClick={reDoQuiz}>{texts.tryAgain}</StandardButton>
            </QuizResultButtonContainer>
          )}
        </Column>
        {props.activity.success.certificate && success && (
          <Column className="column-certificate-content" fullWidth={!props.activity.success.certificate}>
            <CertificateTitleContainer className="certificate-title-container">
              <Title customClass="certificate-title" level={2}>
                {texts.downloadCertificate}
              </Title>
            </CertificateTitleContainer>
            <CertificateContainer className="certificate-form-container">
              <Certificate
                backgroundImage={props.activity.success.certificate.backgroundImage}
                companyLogo={props.activity.success.certificate.companyLogo}
                formationLogo={props.activity.success.certificate.formationLogo}
                formationName={props.activity.success.certificate.formationName}
                primaryColor={props.activity.success.certificate.primaryColor}
                secondaryColor={props.activity.success.certificate.secondaryColor}
                password={props.activity.success.certificate.password}
                onSubmitIdentity={onSubmitIdentity}
              />
            </CertificateContainer>
          </Column>
        )}
      </ColumnContainer>
    </Container>
  );
};

export default observer(ActivityQuizResultComponent);
