const getText = () => ({
  previous: "Précédent",
  next: "Suivant",
  close: "Fermer",
  submit: "Valider",
  score: "Votre score",
  downloadCertificate: "Téléchargez votre certificat",
  inputLastName: "Nom",
  inputFirstName: "Prénom",
  backToHome: "Retour à l'accueil",
  tryAgain: "Refaire le quiz",
  validate: "Valider",
});

export default getText();
