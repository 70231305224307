import React from "react";
import {
  ResourceIconContainer,
  ResourceInfoContainer,
  ResourceName,
  ResourceDescription,
  ResourceIcon,
} from "./StandardResourceItem.style";
import { Resource } from "../../types";
import { ResourceItemContainer } from "./StandardResourceItem.style";

interface Props {
  resource: Resource;
}
const StandardResourceItem: React.FC<Props> = (props) => {
  return (
    <ResourceItemContainer href={props.resource.url} target="_blank" className="resource-item-container">
      <ResourceIconContainer className="resource-icon-container">
        <ResourceIcon resourceType={props.resource.type} />
      </ResourceIconContainer>
      <ResourceInfoContainer className="resource-info-container">
        <ResourceName className="resource-name">{props.resource.name}</ResourceName>
        {props.resource.description && (
          <ResourceDescription className="resource-description">{props.resource.description}</ResourceDescription>
        )}
      </ResourceInfoContainer>
    </ResourceItemContainer>
  );
};

export default StandardResourceItem;
