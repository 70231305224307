import React, { useState, useRef, } from "react";
import { MediaImage, MediaVideo } from "../../types";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  Background,
  VideoBackground,
  VideoBackgroundContainer,
  BackgroundChildrenContainer,
  CarouselBackgroundContainer,
} from "./Background.style";
import { isMobile } from "../../utils";

interface Props {
  background: string | MediaImage | MediaImage[] | MediaVideo;
}

function isMedia(image: any): image is MediaImage | MediaVideo {
  return (image as MediaImage | MediaVideo).type !== undefined;
}
//TODO : check box connector zindex logic
const BackgroundComponent: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const videoref = useRef<HTMLVideoElement>(null);
  const [dimensions, setDimension] = useState({
    computedWidth: 0,
    computedHeight: 0,
    containerWidth: 0,
    containerHeight: 0,
  });

  const onLoadVideo = () => {
    if (videoref.current) {
      //get all needed values
      const videoHeight = videoref.current?.videoHeight;
      const videoWidth = videoref.current?.videoWidth;
      const containerWidth = ref.current?.offsetWidth;
      const containerHeight = ref.current?.offsetHeight;
      if (containerWidth && containerHeight) {
        //Calculating Ratios
        const widthRatio = containerWidth / videoWidth;
        const heightRatio = containerHeight / videoHeight;
        let displayRatio = 0;
        //Choosing the hightest Ratio to apply
        if (widthRatio >= heightRatio) {
          displayRatio = Math.ceil(widthRatio * 100) / 100;
        } else {
          displayRatio = Math.ceil(heightRatio * 100) / 100;
        }
        //call declare state function
        setDimension({
          computedWidth: videoWidth * displayRatio,
          computedHeight: videoHeight * displayRatio,
          containerWidth: containerWidth,
          containerHeight: containerHeight,
        });
      }
    }
  };

  if (typeof props.background === "string") return <Background bg={props.background}>{props.children}</Background>;
  if (isMedia(props.background) && props.background.type === "image")
    return (
      <Background className="background-image" bg={props.background}>
        <BackgroundChildrenContainer>{props.children}</BackgroundChildrenContainer>
      </Background>
    );
  if (isMedia(props.background) && props.background.type === "video")
    return (
      <React.Fragment>
        {!isMobile() && (
          <VideoBackgroundContainer className="video-background-container" ref={ref}>
            <VideoBackground
              computedHeight={dimensions.computedHeight}
              computedWidth={dimensions.computedWidth}
              containerWidth={dimensions.containerWidth}
              containerHeight={dimensions.containerHeight}
              autoPlay
              muted
              loop
              id="myVideo"
              poster={props.background.poster}
              ref={videoref}
              onLoadedData={onLoadVideo}
            >
              <source src={props.background.url} type="video/mp4" />
            </VideoBackground>
            <BackgroundChildrenContainer>{props.children}</BackgroundChildrenContainer>
          </VideoBackgroundContainer>
        )}
        {isMobile() && props.background.poster && (
          <Background className="background-image" bg={{ type: "image", url: props.background.poster }}>
            {props.children}
          </Background>
        )}
      </React.Fragment>
    );
  if (!isMedia(props.background) && typeof props.background !== "string") {
    return (
      <React.Fragment>
        <CarouselBackgroundContainer className="carousel-container">
          <CarouselProvider
            naturalSlideWidth={window.innerWidth}
            isPlaying={true}
            playDirection={"forward"}
            naturalSlideHeight={window.innerHeight * 0.73}
            totalSlides={3}
            infinite={true}
          >
            <Slider style={{ position: "absolute", width: "100%", height: "100%", top: "0", left: "0" }}>
              {props.background.map((image, index) => (
                <React.Fragment key={image.url}>
                  <Slide style={{}} index={index}>
                    <Background className="background-image" bg={image} />
                  </Slide>
                </React.Fragment>
              ))}
            </Slider>
          </CarouselProvider>
          <BackgroundChildrenContainer>{props.children}</BackgroundChildrenContainer>
        </CarouselBackgroundContainer>
      </React.Fragment>
    );
  }
  return <div>ERROR</div>;
};

export default BackgroundComponent;
