import { styled } from "../../theme";

export const HeaderStyle = styled.header<{ hidden?: boolean }>`
  width: 100%;
  height: ${({ hidden }) => (hidden ? "0vh" : "17vh")};
  overflow: ${({ hidden }) => (hidden ? "hidden" : "initial")};
  box-sizing: border-box;
  max-height: ${({ hidden }) => (hidden ? "0vh" : "17vh")};
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  z-index: 8;
  padding-right: 14vw;
  padding-left: 14vw;

  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    max-height: 6em;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const HeaderLogoContainerStyle = styled.a`
  display: block;
  max-height: 15em;
  max-width: 20%;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    max-width: 30%;
  }
`;

export const HeaderTitleContainerStyle = styled.div<{ autoWidth: number }>`
  width: ${({ autoWidth }) => (autoWidth === 0 ? "600px" : `${autoWidth}px`)};
  box-sizing: border-box;
  padding: 1em;
  color: ${({ theme }) => theme.color.primary};
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    width: 100%;
    padding: 0em;
  }
`;
