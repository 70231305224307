import React, { useContext } from "react";
import { Activity } from "../../types";
import {
  ActivityText2,
  ActivityText1,
  ActivityImage,
  ActivityMultimedia,
  ActivityVideo,
  ActivityFakeQuestion,
  ActivityStoryline,
  ActivityQuizResult,
  ActivityQuiz,
  ActivityResourceList,
} from "../";
import { Container } from "./Activity.style";
import { StoreContext } from "../../context";

export interface BaseActivityProps {
  goNext?: () => any;
  goPrevious?: () => any;
  onClickClose?: () => any;
  nextBehaviour?: "close" | "next";
}

interface Props extends BaseActivityProps {
  activity: Activity;
}

const GetRightActivity = (props: Props) => {
  const goNext = props.activity.embedNavigation ? props.goNext : undefined;
  const goPrevious = props.activity.embedNavigation ? props.goPrevious : undefined;
  const onClickClose = props.onClickClose ? props.onClickClose : undefined;
  if (props.activity.type === "text1")
    return (
      <ActivityText1
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        nextBehaviour={props.nextBehaviour}
      />
    );
  if (props.activity.type === "text2")
    return (
      <ActivityText2
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        nextBehaviour={props.nextBehaviour}
      />
    );
  if (props.activity.type === "image")
    return (
      <ActivityImage
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        nextBehaviour={props.nextBehaviour}
      />
    );
  if (props.activity.type === "multimedia1" || props.activity.type === "multimedia2")
    return (
      <ActivityMultimedia
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        nextBehaviour={props.nextBehaviour}
      />
    );
  if (props.activity.type === "video")
    return (
      <ActivityVideo
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        nextBehaviour={props.nextBehaviour}
      />
    );
  if (props.activity.type === "fakeQuestion")
    return (
      <ActivityFakeQuestion
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        nextBehaviour={props.nextBehaviour}
      />
    );
  if (props.activity.type === "storyline")
    return (
      <ActivityStoryline
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        nextBehaviour={props.nextBehaviour}
      />
    );
  if (props.activity.type === "quizResult")
    return (
      <ActivityQuizResult
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        onClickClose={onClickClose}
        nextBehaviour={props.nextBehaviour}
      />
    );
  if (props.activity.type === "realQuiz")
    return (
      <ActivityQuiz
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        nextBehaviour={props.nextBehaviour}
      />
    );
  if (props.activity.type === "resourceList")
    return (
      <ActivityResourceList
        activity={props.activity}
        goPrevious={goPrevious}
        goNext={goNext}
        nextBehaviour={props.nextBehaviour}
      />
    );

  return <div>{`Activity type ${props.activity.type} not yet implemented`}</div>;
};

const ActivityComponent = (props: Props): JSX.Element => {
  const { core } = useContext(StoreContext);
  if (props.activity.isNeededForCompletion) core.setInteractionCompletion(props.activity.id);
  return (
    <Container className="base-activity-container" bg={props.activity.background}>
      <GetRightActivity {...props} />
    </Container>
  );
};

export default ActivityComponent;
