import React, { useReducer } from "react";
import { Activity, MediaImage } from "../../types";
import { Activity as ActivityComponent, StandardButton, StandardCloseButton } from "../";
import { ActivityListContainer, ActivityContainer, ControlsColumn, CloseButtonContainer } from "./ActivityList.style";
import texts from "../../texts";
import NavigationButton from "./NavigationButton.component";
import { core, store } from "../../state";
import { useHistory } from "react-router-dom";

interface Props {
  activityList: Activity[];
  background?: MediaImage | string;
}

type Action = { type: "nextSlide" } | { type: "previousSlide" };
type State = {
  index: number;
  displayArrowPrevious: boolean;
  displayArrowNext: boolean;
  nextBehaviour: "next" | "close";
};

const ActivityList: React.FC<Props> = (props) => {
  const history = useHistory();
  const activityListReducer = (state: State, action: Action): State => {
    const newState = { ...state };
    if (action.type === "previousSlide") {
      newState.index--;
    }
    if (action.type === "nextSlide") {
      newState.index++;
    }
    newState.nextBehaviour = newState.index < props.activityList.length - 1 ? "next" : "close";
    newState.displayArrowNext =
      newState.index < props.activityList.length - 1 && !props.activityList[newState.index].embedNavigation;
    newState.displayArrowPrevious = newState.index !== 0 && !props.activityList[newState.index].embedNavigation;
    return newState;
  };
  const [state, dispatch] = useReducer(activityListReducer, {
    index: 0,
    displayArrowPrevious: false && !props.activityList[0].embedNavigation,
    displayArrowNext: true && !props.activityList[0].embedNavigation && props.activityList.length > 1,
    nextBehaviour: props.activityList.length > 1 ? "next" : "close",
  });

  const goPrevious = () => dispatch({ type: "previousSlide" });
  const goNext = () => {
    if (state.nextBehaviour === "next") dispatch({ type: "nextSlide" });
    else onClickClose();
  };
  const onClickClose = () => {
    if (store.currentBox) {
      const parentBox = core.getBoxParent(store.currentBox.id, store.content.boxList);
      history.push(parentBox ? `/box?idBox=${parentBox.id}` : "/");
    }
  };
  return (
    <ActivityListContainer className="activity-list-container" bg={props.background}>
      <ControlsColumn className="activity-list-controls-column">
        <NavigationButton reverseDirection={false} hidden={!state.displayArrowPrevious} onClick={goPrevious} />
      </ControlsColumn>
      <ActivityContainer className="activity-list-activity-container">
        <ActivityComponent
          activity={props.activityList[state.index]}
          onClickClose={onClickClose}
          goPrevious={goPrevious}
          goNext={goNext}
        />
      </ActivityContainer>
      <ControlsColumn className="activity-list-controls-column">
        <CloseButtonContainer className="activity-list-close-button-container">
          <StandardCloseButton onClick={onClickClose} />
        </CloseButtonContainer>
        <NavigationButton reverseDirection={true} hidden={!state.displayArrowNext} onClick={goNext} />
      </ControlsColumn>
    </ActivityListContainer>
  );
};

export default ActivityList;
