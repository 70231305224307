import React, { useEffect, useRef, useState } from "react";
import { MainMenuStyle, MainMenuContainerStyle } from "./MainMenu.style";
import { Media, MediaImage, Box } from "../../types";
import BoxList from "../BoxList/BoxList.component";
import { Background } from "..";

interface Props {
  bg: Media | MediaImage[];
  boxList: Box[];
  onChangeBoxListContainerWidth?: (boxWith: number) => void;
}

const MainMenu: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [boxWidth, setBoxWidth] = useState(0);

  useEffect(() => {
    if (boxWidth === 0) {
      setBoxWidth(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);

  useEffect(() => {
    if (boxWidth !== 0 && props.onChangeBoxListContainerWidth) {
      props.onChangeBoxListContainerWidth(boxWidth);
    }
  }, [boxWidth]);
  return (
    <Background background={props.bg}>
      <MainMenuStyle className={"main-menu"}>
        <MainMenuContainerStyle autoWidth={boxWidth} ref={ref} className={"main-menu-boxlist-container"}>
          <BoxList boxList={props.boxList} />
        </MainMenuContainerStyle>
      </MainMenuStyle>
    </Background>
  );
};

export default MainMenu;
