import React from "react";
import { MediaImage } from "../../types";
import { HeaderStyle, HeaderLogoContainerStyle, HeaderTitleContainerStyle } from "./Header.style";
import Title from "../Title/Title.component";

interface Props {
  logo: MediaImage;
  title: string;
  autoWidth: number;
  hidden?: boolean;
}

const Header: React.FC<Props> = (props) => {
  return (
    <HeaderStyle className="header" hidden={props.hidden}>
      <HeaderLogoContainerStyle className="header-logo-container" href="#">
        <img src={props.logo.url} width="100%" alt={props.logo.alt} />
      </HeaderLogoContainerStyle>
      <HeaderTitleContainerStyle autoWidth={props.autoWidth} className="header-title-container">
        <Title level={1}>
          <div dangerouslySetInnerHTML={{ __html: props.title }} />
        </Title>
      </HeaderTitleContainerStyle>
    </HeaderStyle>
  );
};

export default Header;
