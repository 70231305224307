import React from "react";
import { Media } from "../../types";
import { Image, Video } from "./MediaContainer.style";
import { duration } from "@material-ui/core";

const getVideoType = (url: string) => {
  const types = [
    {
      name: "mp4",
      regex: "/*.mp4",
    },
    {
      name: "webm",
      regex: "/*.webm",
    },
    {
      name: "ogg",
      regex: "/*.ogv",
    },
  ];
  for (let i = 0, n = types.length; i < n; i++) {
    if (RegExp(types[i].regex).test(url)) return types[i].name;
  }
  return "mp4";
};

interface Props {
  media: Media;
  style?: React.CSSProperties;
}
const MediaContainerComponent = (props: Props) => {
  if (props.media.type === "image") return <Image style={props.style} src={props.media.url} alt={props.media.alt} />;
  else
    return (
      <Video controls>
        <source src={props.media.url} type={`video/${getVideoType(props.media.url)}`} />
        {props.media.subtitle && <track src={props.media.subtitle} default kind="captions" />}
      </Video>
    );
};

export default MediaContainerComponent;
