import { styled } from "../../theme";
import { MediaImage } from "../../types";

interface ActivityListContainerProps {
  bg?: MediaImage | string;
}
export const ActivityListContainer = styled.div<ActivityListContainerProps>`
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  background: ${({ bg }) => (bg ? (typeof bg === "string" ? bg : `url("${bg.url}")`) : "none")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ActivityContainer = styled.div`
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  position: relative;
`;

export const CloseButtonContainer = styled.div`
  top: 0em;
  margin-top: 2em;
  z-index: 10;
  position: absolute;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
  }
`;

export const ControlsColumn = styled.div`
  width: 10%;
  min-height: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    width: inherit;
  }
`;
