import React from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { StandardResourceItem } from "..";
import { ActivityResource } from "../../types";
import {
  ResourceListContainer,
  ResourceListOverlay,
  ItemContainer,
  Container,
  ResourceListTitleContainer,
  SideBarStyle,
} from "./ActivityResourceList.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import Title from "../Title/Title.component";
import {} from "../StandardResourceItem/StandardResourceItem.style";
import ReactDOM from "react-dom";
import content from "../../content";

interface Props extends BaseActivityProps {
  activity: ActivityResource;
}
const appRoot = document.getElementById("root");

const renderResourceOverlay = (overlayColor: string) => {
  return <ResourceListOverlay className="resources-list-overlay" overlayColor={overlayColor} />;
};

const ActivityResourceListComponent: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {props.activity.overlayColor &&
        appRoot !== null &&
        ReactDOM.createPortal(renderResourceOverlay(props.activity.overlayColor), appRoot)}
      <Container className="resources-container">
        {props.activity.title && (
          <ResourceListTitleContainer className="resources-title-container">
            <Title level={2}>{props.activity.title}</Title>
          </ResourceListTitleContainer>
        )}
        <ResourceListContainer className="resource-list-container">
          <SideBarStyle>
            <SimpleBar style={{ height: "100%", width: "100%" }}>
              {props.activity.resourceList.map((item) => (
                <ItemContainer key={item.id}>
                  <StandardResourceItem resource={item} />
                </ItemContainer>
              ))}
            </SimpleBar>
          </SideBarStyle>
        </ResourceListContainer>
      </Container>
    </React.Fragment>
  );
};

export default ActivityResourceListComponent;
