import React, { useContext } from "react";
import { StandardButton, MediaContainer } from "..";
import { ActivityMultimedia } from "../../types";
import { ColumnContainer, Column, MultimediaTitleContainer, Container } from "./ActivityMultimedia.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import Title from "../Title/Title.component";
import texts from "../../texts";

interface Props extends BaseActivityProps {
  activity: ActivityMultimedia;
}

const ActivityMultimediaComponent: React.FC<Props> = (props) => {
  const { core } = useContext(StoreContext);
  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  if (props.activity.tracking?.successCondition === "open") {
    core.setInteractionResult(props.activity.id, "correct");
  }
  return (
    <Container className="multimedia-container">
      {props.activity.title && (
        <MultimediaTitleContainer className="multimedia-title-container">
          <Title level={2}>{props.activity.title}</Title>
        </MultimediaTitleContainer>
      )}
      <ColumnContainer reverse={props.activity.type === "multimedia2"}>
        <Column>
          <div
            id={props.activity.id}
            className={`${props.activity.type} rich-text-container`}
            dangerouslySetInnerHTML={{ __html: props.activity.text }}
          />
          {props.goNext && (
            <StandardButton onClick={onValidate}>
              {props.nextBehaviour === "close" ? texts.close : texts.next}
            </StandardButton>
          )}
        </Column>
        <Column className="multimedia-media-column">
          <MediaContainer media={props.activity.media} />
        </Column>
      </ColumnContainer>
    </Container>
  );
};

export default observer(ActivityMultimediaComponent);
