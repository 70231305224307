import { styled } from "./theme";

export const AppStyle = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.mainFont};
  font-weight: normal;
  font-style: normal;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    height: inherit;
  }
`;
export const MainContainer = styled.main<{ fullscreen: boolean }>`
  min-height: ${({ fullscreen }) => (fullscreen ? "100vh" : "73vh")};
  height: ${({ fullscreen }) => (fullscreen ? "100vh" : "73vh")};
`;

export const ContainerInModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
