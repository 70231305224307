import React, { useState } from "react";
import {
  FooterStyle,
  FooterLeftContainerStyle,
  FooterLeftItemStyle,
  FooterLeftItemLinkStyle,
  FooterRightContainerStyle,
  FooterRightItemLinkStyle,
  FooterRightItemStyle,
  ContainerInModal,
  FooterLeftItemModalStyle,
} from "./Footer.style";
import { FooterLeftItem, FooterRightItem, MediaImage } from "../../types";
import ReactModal from "react-modal";
import { StandardCloseButton } from "../StandardCloseButton/StandardCloseButton.style";

interface Props {
  leftItemList: FooterLeftItem[];
  rightItemList: FooterRightItem[];
  footerBackground: MediaImage | string;
  hidden?: boolean;
}

const Footer: React.FC<Props> = (props) => {
  const [modalContent, setModalContent] = useState<string | undefined>();
  const onClickContentItem = (content: string) => () => {
    setModalContent(content);
  };
  return (
    <FooterStyle className="footer" bg={props.footerBackground} hidden={props.hidden}>
      <LeftItemList onClickContentItem={onClickContentItem} itemList={props.leftItemList} />
      <RightItemList itemList={props.rightItemList} />
      <ReactModal
        style={{
          overlay: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
          },
          content: {
            position: "unset",
            maxWidth: "70vw",
            maxHeight: "90vh",
          },
        }}
        isOpen={modalContent !== undefined}
        onRequestClose={() => setModalContent(undefined)}
      >
        <ContainerInModal>
          <StandardCloseButton style={{ alignSelf: "flex-end" }} onClick={() => setModalContent(undefined)} />
          <div
            style={{ paddingRight: "3em", paddingLeft: "3em" }}
            dangerouslySetInnerHTML={{ __html: modalContent || "" }}
          />
        </ContainerInModal>
      </ReactModal>
    </FooterStyle>
  );
};

const LeftItemList = (props: { itemList: FooterLeftItem[]; onClickContentItem: (content: string) => () => any }) => {
  return (
    <FooterLeftContainerStyle className="footer-left-container">
      {props.itemList.map((item) => (
        <FooterLeftItemStyle key={item.id} className="footer-left-item">
          {item.url && (
            <FooterLeftItemLinkStyle
              className="footer-left-link"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.text}
            </FooterLeftItemLinkStyle>
          )}
          {item.content && (
            <FooterLeftItemModalStyle
              className="footer-left-modal-link"
              onClick={props.onClickContentItem(item.content)}
            >
              {item.text}
            </FooterLeftItemModalStyle>
          )}
        </FooterLeftItemStyle>
      ))}
    </FooterLeftContainerStyle>
  );
};
const RightItemList = (props: { itemList: FooterRightItem[] }) => {
  return (
    <FooterRightContainerStyle className="footer-right-container">
      {props.itemList.map((item) => (
        <FooterRightItemStyle key={item.id} className="footer-right-item">
          {item.url && (
            <FooterRightItemLinkStyle
              className="footer-left-link"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={item.image.url} alt={item.image.alt} />
            </FooterRightItemLinkStyle>
          )}
          {!item.url && <img src={item.image.url} alt={item.image.alt} />}
        </FooterRightItemStyle>
      ))}
    </FooterRightContainerStyle>
  );
};

export default Footer;
