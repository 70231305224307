import React, { useContext } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import Title from "../Title/Title.component";
import { ActivityImage } from "../../types";
import { MediaContainer, StandardButton } from "..";
import texts from "../../texts";
import { Container, ImageContainer, ImageTitleContainer } from "./ActivityImage.style";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";

interface Props extends BaseActivityProps {
  activity: ActivityImage;
}

const ActivityImageComponent: React.FC<Props> = (props) => {
  const { core } = useContext(StoreContext);
  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  if (props.activity.tracking?.successCondition === "open") {
    core.setInteractionResult(props.activity.id, "correct");
  }
  return (
    <Container>
      {props.activity.title && (
        <ImageTitleContainer className="image-title-container">
          <Title level={2}>{props.activity.title}</Title>
        </ImageTitleContainer>
      )}
      <ImageContainer>
        <MediaContainer media={props.activity.image} />
      </ImageContainer>
      {props.goNext && (
        <StandardButton onClick={onValidate}>
          {props.nextBehaviour === "close" ? texts.close : texts.next}
        </StandardButton>
      )}
    </Container>
  );
};

export default observer(ActivityImageComponent);
