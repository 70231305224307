import React, { useContext } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import { Container, StorylineIframe, StorylineTitleContainer } from "./ActivityStoryline.style";
import { ActivityStoryline } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import Title from "../Title/Title.component";

interface Props extends BaseActivityProps {
  activity: ActivityStoryline;
}

const ActivityStorylineContainer: React.FC<Props> = (props) => {
  const { core } = useContext(StoreContext);
  if (props.activity.tracking?.successCondition === "open") {
    core.setInteractionResult(props.activity.id, "correct");
  }
  return (
    <Container className="activity-storyline-container">
      {props.activity.title && (
        <StorylineTitleContainer className="storyline-title-container">
          <Title level={2}>{props.activity.title}</Title>
        </StorylineTitleContainer>
      )}
      <StorylineIframe title={props.activity.title} allowFullScreen={true} src={props.activity.desktopUrl} />
    </Container>
  );
};

export default observer(ActivityStorylineContainer);
