import { styled } from "../../theme";

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    flex-direction: column;
  }
`;

export const TextColumn = styled.div`
  width: 50%;
  max-width: 50%;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    width: 100%;
    max-width: 100%;
  }
`;
export const TextTitleContainer = styled.div``;