import { styled } from "../../theme";

//TODO add responsive style
export const ResourceListOverlay = styled.div<{ overlayColor: string }>`
  z-index: 8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ overlayColor }) => `${overlayColor}`};
  opacity: 0.6;
  animation: fadeIn linear 0.2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

export const Container = styled.div`
  max-width: 100%;
  margin-top: 2.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 90%;
`;
export const ResourceListTitleContainer = styled.div``;

export const ResourceListContainer = styled.ul`
  display: flex;
  z-index: 9;
  width: 40%;
  padding: 2em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  list-style: none;
  border-radius: 2em;
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
`;

export const ItemContainer = styled.li`
  width: 100%;
  box-sizing: border-box;
  min-height: 4em;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    width: 90%;
    padding-bottom: 1.5em;
  }
`;

export const SideBarStyle = styled.div`
  width: 100%;
  height: 100%;
  .simplebar-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .simplebar-scrollbar.simplebar-visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 1;
    transition: opacity 0s linear;
  }
  .simplebar-scroll-content {
  }
  .simplebar-scrollbar::before {
    background-color: ${({ theme }) => theme.color.scrollbar};
  }
  .track {
  }
`;
