import { styled } from "../../theme";

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.div`
  padding: 1em;
  box-sizing: border-box;
`;

export const ImageTitleContainer = styled.div``;
