import { styled } from "../../theme";

interface ColumnContainerProps {
  reverse?: boolean;
}

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const ColumnContainer = styled.div<ColumnContainerProps>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    height: initial;
    min-height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
`;
export const ScoreBlock = styled.span`
  box-sizing: border-box;
`;
export const ScoreText = styled(ScoreBlock)``;
export const ScoreNumber = styled(ScoreBlock)``;
export const ScoreUnit = styled(ScoreBlock)``;

export const Column = styled.div<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};
  min-width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  &.column-result-content {
  }
  &.column-certificate-content {
  }
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;
export const QuizResultTextContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  min-width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  p {
    min-width: 70%;
    @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
      min-width: 100%;
    }
  }
`;
export const QuizResultButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  button {
    width: 30%;
    @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
      width: 60%;
      margin-top: 5%;
    }
  }
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    flex-direction: column;
  }
`;

export const CertificateTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const CertificateContainer = styled.div`
  width: 70%;
`;
export const QuizResultTitleContainer = styled.div``;
