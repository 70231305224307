import { useState, useEffect } from "react";
import { theme } from "./theme";

export const lightenColor = function (color: string, percent: number): string {
  const num = parseInt(color.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;

  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

export const pickTextColorBasedOnBgColor = function (bgColor: string, lightColor: string, darkColor: string): string {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
};

export const isMobile = function () {
  const screenWidth = window.innerWidth;
  const breakPoint = parseInt(theme.mobileFirst.breakPoint.replace("px", ""));
  return screenWidth < breakPoint;
};
export const isPortrait = function () {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  return screenWidth < screenHeight;
};

export const findItemAndIndexById = <T extends { id: string }>(
  id: string,
  tabWithId: T[],
):
  | {
      item: T;
      index: number;
    }
  | undefined => {
  try {
    let itemIndex = -1;
    const item = tabWithId.find((item, index) => {
      itemIndex = index;
      return item.id === id;
    });
    if (item) {
      return {
        item,
        index: itemIndex,
      };
    } else return undefined;
  } catch (e) {
    return undefined;
  }
};

export type RequiredBy<T, K extends keyof T> = Pick<T, K> & Required<Pick<T, K>>;

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{ width?: number; height?: number }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
