import { styled } from "../../theme";

export const Container = styled.div`
  max-width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  .question-title {
    font-size: 2em;
    margin-top: 2em;
  }
  .quiz-container,
  .question-internal-container {
    height: 100%;
  }
  .question-instructions {
    font-size: 1.4em;
  }
`;
export const QuizTitleContainer = styled.div``;
