import { styled } from "../../theme";
import Button from "@material-ui/core/Button";
import { pickTextColorBasedOnBgColor } from "../../utils";

export const IdentityForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ValidateButton = styled(Button)`
  && {
    margin-top: 1em;
    background-color: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => pickTextColorBasedOnBgColor(theme.color.secondary, "#ffffff", theme.color.text)};
    :hover {
      background-color: ${({ theme }) => theme.color.secondary};
      opacity: 0.8;
    }
  }
`;
