import { styled } from "../../theme";
import { Media } from "../../types";
import { Link } from "react-router-dom";

export const BoxItemStyle = styled(Link)<{ bg: Media | string }>`
  position: relative;
  appearance: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  border: inherit;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  border-radius: 2em;
  padding: 1.5em;
  background: ${({ bg }) => (bg ? (typeof bg === "string" ? bg : `url("${bg.url}")`) : "none")};
  background-size: cover;
  background-position: center;
  text-transform: none;
  &.disabled {
    cursor: inherit;
    pointer-events: none;
  }
  &:not(.disabled)::before {
    z-index: 500;
    border-radius: 2em;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background-color: rgba(255, 255, 255, 0.2);
  }
  &:not([disabled]):hover::before {
    display: block;
    opacity: 1;
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    flex-direction: row;
  }
`;

export const BoxItemTitleContainerStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-grow: 2;
  max-height: 80%;

  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    max-height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.notebook.breakPoint}) {
    font-size: 0.85em;
  }
`;

export const BoxItemIconContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 50%;
  flex-grow: 1;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    display: none;
  }
`;

export const BoxItemIconStyle = styled.span<{ bg: string }>`
  width: 100%;
  height: 100%;
  background-image: ${({ bg }) => `url("${bg}")`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
`;

export const BoxItemBadgeStyle = styled.span`
  position: absolute;
  background-color: green;
  right: -1.5em;
  top: -1.5em;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  z-index: 1000;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    right: -0.5em;
    top: -0.5em;
    width: 2em;
    height: 2em;
  }
`;
export const BoxItemVisitedBadgeStyle = styled(BoxItemBadgeStyle)`
  background-image: url(${({ theme }) => theme.icons.boxVisited});
  background-color: ${({ theme }) => theme.color.valid};
`;
export const BoxItemLockedBadgeStyle = styled(BoxItemBadgeStyle)`
  background-image: url(${({ theme }) => theme.icons.boxLocked});
  background-color: ${({ theme }) => theme.color.invalid};
`;
export const BoxItemConnector = styled.span`
  background-color: ${({ theme }) => theme.color.boxConnector};
  position: absolute;
  z-index: -1;
  &.direction_left,
  &.direction_right {
    top: 45%;
    width: 30%;
    min-height: 10%;
  }
  &.direction_left {
    left: -20%;
  }
  &.direction_right {
    right: -20%;
  }
  &.direction_up,
  &.direction_down {
    left: 45%;
    width: 10%;
    min-height: 30%;
  }
  &.direction_up {
    top: -20%;
  }
  &.direction_down {
    bottom: -20%;
  }
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    display: none;
  }
`;
