import { styled } from "../../theme";

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  height: 100%;
`;
export const VideoTitleContainer = styled.div``;

export const VideoContainer = styled.div`
  max-height: 90%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    height: initial;
  }
  video:focus {
    outline: none;
  }
`;
