import React, { useRef, useContext } from "react";
import { BoxListStyle, BoxListItemContainer } from "./BoxList.style";
import { BoxItem } from "..";
import { Box } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";

interface Props {
  boxList: Box[];
}

function boxHeightRender(boxListLenght: number) {
  if (boxListLenght > 6) {
    return 30;
  }
  return 47.5;
}

const BoxList: React.FC<Props> = (props) => {
  const { store, core } = useContext(StoreContext);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <BoxListStyle className={"boxlist"}>
      {props.boxList.map((box) => (
        <BoxListItemContainer
          ref={ref}
          autoHeight={boxHeightRender(props.boxList.length)}
          className={"boxlist-item-container"}
          key={box.id}
          hideMobile={box.type === "empty"}
        >
          <BoxItem
            box={box}
            linkTo={
              box.type === "menu"
                ? `/box?idBox=${box.id}`
                : box.type === "content"
                ? `/activity-list?idBox=${box.id}`
                : undefined
            }
          />
        </BoxListItemContainer>
      ))}
    </BoxListStyle>
  );
};

export default observer(BoxList);
