import { styled } from "../../theme";
import { MediaImage } from "../../types";

export const MainMenuStyle = styled.div`
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  flex-grow: 1;
  padding-right: 14vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    flex-direction: column;
    justify-content: center;
    height: inherit;
    padding-right: inherit;
  }
`;

export const MainMenuContainerStyle = styled.div<{ autoWidth: number }>`
  box-sizing: border-box;
  padding: 1em;
  width: ${({ autoWidth }) => `${autoWidth}px`};
  max-width: 50%;
  min-height: 100%;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    width: 100%;
    max-width: inherit;
  }
`;
