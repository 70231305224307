import { styled } from "../../theme";
//TODO use full height in desktop case
export const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    height: initial;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
