import { styled } from "../../theme";
import { lightenColor } from "../../utils";

export const StandardCloseButton = styled.button`
  border: solid 1px;
  border-color: ${({ theme }) => theme.color.primary};
  background-color: ${({ theme }) => theme.color.primary};
  background-image: url(${({ theme }) => theme.icons.closeCross});
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
  width: 2.5em;
  height: 2.5em;
  border-radius: 2em;
  transition: all 0.2s;
  cursor: pointer;
  box-sizing: border-box;
  :hover {
    border-color: ${(props) => lightenColor(props.color || props.theme.color.primary, 5)};
    background-color: ${(props) => lightenColor(props.color || props.theme.color.primary, 5)};
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
    transform: rotate(180deg);
  }
  :focus {
    border: none;
    outline: none;
  }
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
  }
`;
