/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Store } from "./store";
import { Box, Activity, ContentBox } from "../types";
import { createWrapper } from "@upandgo/scorm-wrapper";
import { action, autorun, toJS } from "mobx";
import { findItemAndIndexById, RequiredBy } from "../utils";
import axios from "axios";
import { AnswerResult, QuestionAggregate } from "@upandgo/react-quiz";

class Core {
  private store: Store;
  private scorm: ReturnType<typeof createWrapper>;

  constructor(store: Store, wrapperCreationFunc: typeof createWrapper) {
    this.store = store;
    this.scorm = wrapperCreationFunc({
      baseStorageType: process.env.NODE_ENV === "development" ? "local" : "2004",
    });
    void this.bootstrap();
  }

  private bootstrap = async (): Promise<void> => {
    void this.scorm.init({ startSession: true, isDebug: false });
    await this.insertSCORMStateInStore();
    for (let i = 0, n = this.store.content.boxList.length; i < n; i++) {
      autorun(this.computeObjectiveStatus(this.store.content.boxList[i]), { requiresObservable: true });
    }
    autorun(this.computeGlobalStatus, { requiresObservable: true });
    if (this.store.content.shouldStoreInteractionAndObjectiveInSuspendData)
      autorun(this.storeInteractionAndObjectiveListInSuspendData);
    if (this.store.content.shouldInitializeScore) {
      const score = await this.scorm.getBaseData("scoreRaw");
      if (!score) await this.scorm.setBaseData("scoreRaw", 0);
    }
    void this.scorm.setBaseData("exit", "suspend");
  };

  close = (): void => {
    this.scorm.close();
  };

  sendSCORMStatsToServer = async (props: { url: string; identity: any }) => {
    await axios.post(props.url, {
      applicationId: this.store.content.idApplication,
      user: props.identity,
      scormState: {
        successStatus: this.store.successStatus,
      },
    });
  };

  storeInteractionAndObjectiveListInSuspendData = async () => {
    await this.scorm.setBaseData(
      "suspendData",
      JSON.stringify({ objectiveList: this.store.objectiveList, interactionList: this.store.interactionList }),
    );
  };

  @action
  insertSCORMStateInStore = async () => {
    const suspendDataString = await this.scorm.getBaseData("suspendData");
    const isSuspendDataAlreadyPresent =
      typeof suspendDataString === "string" && suspendDataString !== undefined && suspendDataString.length > 0;
    this.store.isFirstTime = !isSuspendDataAlreadyPresent;

    this.store.successStatus = ((await this.scorm.getBaseData("successStatus")) as any) || "unknown";
    this.store.completionStatus = ((await this.scorm.getBaseData("completeStatus")) as any) || "not attempted";

    if (this.store.content.shouldStoreInteractionAndObjectiveInSuspendData) {
      if (isSuspendDataAlreadyPresent) {
        const suspendData = JSON.parse(suspendDataString as string);
        if (suspendData.interactionList) {
          this.store.interactionList = suspendData.interactionList;
        }
        if (suspendData.objectiveList) {
          this.store.objectiveList = suspendData.objectiveList;
        }
      }
    } else {
      const interactionsCount =
        process.env.NODE_ENV === "development" ? 12 : await this.scorm.getBaseData("interactionsCount");

      for (let i = 0, n = interactionsCount; i < n; i++) {
        const [id, result] = await Promise.all([
          this.scorm.getInteractionData("id", i),
          this.scorm.getInteractionData("result", i),
        ]);
        if (id) {
          this.store.interactionList.push({
            id: id as string,
            result: result as "correct" | "incorrect" | "unanticipated" | "neutral",
          });
        }
      }
    }
  };

  @action
  setInteractionResult = (idActivity: string, result: "correct" | "incorrect"): void => {
    const findResult = findItemAndIndexById(idActivity, this.store.interactionList);
    if (findResult) {
      this.store.interactionList[findResult.index].result = result;
    } else {
      this.store.interactionList.push({ id: idActivity, result });
    }
    if (!this.store.content.shouldStoreInteractionAndObjectiveInSuspendData) {
      void this.scorm.setInteractionData(
        "id",
        findResult ? findResult.index : this.store.interactionList.length - 1,
        idActivity,
      );
      void this.scorm.setInteractionData(
        "result",
        findResult ? findResult.index : this.store.interactionList.length - 1,
        result,
      );
    }
  };

  @action
  setInteractionCompletion = async (idActivity: string) => {
    const findResult = findItemAndIndexById(idActivity, this.store.interactionList);
    const activityNeededForCompletionList = this.getAllActivities(this.store.content.boxList).filter(
      (act) => act.isNeededForCompletion,
    );
    if (!findResult) {
      this.store.interactionList.push({ id: idActivity, result: "neutral" });
    }
    if (!this.store.content.shouldStoreInteractionAndObjectiveInSuspendData) {
      await this.scorm.setInteractionData(
        "id",
        findResult ? findResult.index : this.store.interactionList.length - 1,
        idActivity,
      );
      await this.scorm.setInteractionData(
        "result",
        findResult ? findResult.index : this.store.interactionList.length - 1,
        findResult ? findResult.item.result : "neutral",
      );
    }

    if (this.store.completionStatus !== "completed") {
      const completionStatus =
        this.store.interactionList.length < activityNeededForCompletionList.length ? "incomplete" : "completed";
      await this.scorm.setBaseData("completeStatus", completionStatus);
      this.store.completionStatus = completionStatus;
    }
  };

  computeGlobalStatus = async () => {
    if (this.store.content.successOnSuccessOfObjectiveList) {
      let moduleSuccessStatus: "passed" | "failed" | "unknown" = "passed";
      for (let i = 0, n = this.store.content.successOnSuccessOfObjectiveList.length; i < n; i++) {
        const findResult = findItemAndIndexById(
          this.store.content.successOnSuccessOfObjectiveList[i],
          this.store.objectiveList,
        );
        if (!findResult) {
          moduleSuccessStatus = "failed";
          break;
        }
        if (findResult && findResult.item.successStatus !== "passed") {
          moduleSuccessStatus = "failed";
          break;
        }
      }
      this.store.successStatus = moduleSuccessStatus;
      await this.scorm.setBaseData("successStatus", moduleSuccessStatus);
    }
  };

  computeObjectiveSuccess = (box: RequiredBy<ContentBox, "objective">): "passed" | "failed" => {
    let boxSuccessStatus: "passed" | "failed" = "passed";
    for (let i = 0, n = box.objective.successOnSucessOfInteractionList.length; i < n; i++) {
      const isInteractionSucceeded = this.store.interactionList.some(
        (int) => int.id === box.objective.successOnSucessOfInteractionList[i] && int.result === "correct",
      );
      if (!isInteractionSucceeded) {
        boxSuccessStatus = "failed";
        break;
      }
    }
    return boxSuccessStatus;
  };

  computeObjectiveCompletion = (box: ContentBox): "completed" | "incomplete" | "not attempted" => {
    let activitiesCompleted = 0;
    const activityNeededForCompletionList = box.activityList.filter((act) => act.isNeededForCompletion);

    for (let i = 0, n = activityNeededForCompletionList.length; i < n; i++) {
      const interaction = this.store.interactionList.find((int) => int.id === activityNeededForCompletionList[i].id);
      if (interaction) activitiesCompleted++;
    }

    if (activitiesCompleted === 0) return "not attempted";
    if (activitiesCompleted === activityNeededForCompletionList.length) return "completed";
    return "incomplete";
  };

  computeObjectiveStatus = (box: Box) => async () => {
    if (box.type === "menu") {
      for (let i = 0, n = box.boxList.length; i < n; i++) {
        void this.computeObjectiveStatus(box.boxList[i])();
      }
    } else if (box.type === "content") {
      const boxSuccessStatus = box.objective
        ? this.computeObjectiveSuccess({ ...box, objective: box.objective })
        : "unknown";
      const boxCompletionStatus = this.computeObjectiveCompletion(box);

      const findResult = findItemAndIndexById(box.id, this.store.objectiveList);
      if (findResult && findResult.item.successStatus === boxSuccessStatus) return;

      if (boxSuccessStatus === "passed" && findResult) {
        this.store.objectiveList[findResult.index].successStatus = "passed";
        this.store.objectiveList[findResult.index].completionStatus = boxCompletionStatus;
      } else if (boxSuccessStatus !== "passed" && findResult) {
        this.store.objectiveList[findResult.index].successStatus = "failed";
        this.store.objectiveList[findResult.index].completionStatus = boxCompletionStatus;
      } else if (boxSuccessStatus === "passed" && !findResult) {
        this.store.objectiveList.push({ id: box.id, successStatus: "passed", completionStatus: boxCompletionStatus });
      } else if (boxSuccessStatus !== "passed" && !findResult) {
        this.store.objectiveList.push({ id: box.id, successStatus: "failed", completionStatus: boxCompletionStatus });
      }
      const boxIndex = findResult ? findResult.index : this.store.objectiveList.length - 1;
      if (!this.store.content.shouldStoreInteractionAndObjectiveInSuspendData) {
        await this.scorm.setObjectiveData("id", boxIndex, box.id);
        await this.scorm.setObjectiveData("successStatus", boxIndex, boxSuccessStatus);
      }
    }
  };

  @action
  setCurrentBox = (box: Box | undefined): void => {
    this.store.currentBox = box;
  };

  getBox = (id: string, boxList: Box[]): Box | undefined => {
    let result: Box | undefined = undefined;
    for (let i = 0, n = boxList.length; i < n; i++) {
      const box = boxList[i];
      if (box.id === id) {
        return box;
      }
      if (box.type === "menu") {
        result = this.getBox(id, box.boxList);
        if (result) break;
      }
    }
    return result;
  };

  getBoxParent = (id: string, boxList: Box[]): Box | undefined => {
    let result: Box | undefined = undefined;
    for (let i = 0, n = boxList.length; i < n; i++) {
      const box = boxList[i];
      if (box.type === "menu") {
        const foundBox = box.boxList.find((itemBox) => itemBox.id === id);
        if (foundBox) return box;
        else {
          result = this.getBoxParent(id, box.boxList);
          if (result) break;
        }
      }
    }
    return result;
  };

  getActivityList = (id: string, box: Box): Activity | undefined => {
    if (box.type !== "content") return undefined;
    return box.activityList.find((act) => act.id === id);
  };

  getAllActivities = (boxList: Box[]): Activity[] => {
    const activityList: Activity[] = [];
    for (let i = 0, n = boxList.length; i < n; i++) {
      const box = boxList[i];
      if (box.type === "content") {
        activityList.push(...box.activityList);
      } else if (box.type === "menu") {
        activityList.push(...this.getAllActivities(box.boxList));
      }
    }
    return activityList;
  };

  isBoxSuccess = (id: string) =>
    this.store.objectiveList.some((obj) => obj.id === id && obj.successStatus === "passed");

  isBoxComplete = (id: string) =>
    this.store.objectiveList.some((obj) => obj.id === id && obj.completionStatus === "completed");

  isBoxLocked = (id: string) => {
    const box = this.getBox(id, this.store.content.boxList);
    if (!box) return false;
    const listOfBoxWhichCompletionUnlockBox = box.lockedByCompletionOfBoxList;
    if (listOfBoxWhichCompletionUnlockBox) {
      for (let i = 0, n = listOfBoxWhichCompletionUnlockBox.length; i < n; i++) {
        const currObj = this.store.objectiveList.some(
          (obj) => obj.id === listOfBoxWhichCompletionUnlockBox[i] && obj.completionStatus === "completed",
        );
        if (!currObj) return true;
      }
    }
    //TODO: Finish isBoxLocked to account the success
    const boxListSuccessNeededToUnlockBox = box.lockedByCompletionOfBoxList;

    return false;
  };

  setFinalQuizAnswerResult = async ({
    quiz,
    answer,
    threshold,
  }: {
    quiz: QuestionAggregate;
    answer: AnswerResult;
    threshold: number;
  }) => {
    if (!this.store.quizResults[quiz.id]) {
      this.store.quizResults[quiz.id] = {
        answerList: [answer],
        successStatus: "unknown",
        numberOfRightAnswers: 0,
        quiz,
      };
    } else {
      this.store.quizResults[quiz.id].answerList.push(answer);
    }
    let numberOfRightAnswers = this.store.quizResults[quiz.id].answerList.length;
    for (let i = 0, n = quiz.questionList.length; i < n; i++) {
      const validAnswers = quiz.questionList[i].answerList.filter((answer) => answer.valid)!;
      const userAnswers = this.store.quizResults[quiz.id].answerList[i]?.idAnswers || [];
      if (validAnswers.length !== userAnswers.length) {
        numberOfRightAnswers--;
        continue;
      }
      for (let j = 0, m = validAnswers.length; j < m; j++) {
        if (!userAnswers.includes(validAnswers[j].id)) {
          numberOfRightAnswers--;
          break;
        }
      }
    }
    this.store.quizResults[quiz.id].numberOfRightAnswers = numberOfRightAnswers;
    this.store.quizResults[quiz.id].successStatus = numberOfRightAnswers >= threshold ? "passed" : "failed";
    await this.scorm.setBaseData("scoreMin", 0);
    await this.scorm.setBaseData("scoreMax", quiz.questionList.length);
    await this.scorm.setBaseData("scoreRaw", Math.max(numberOfRightAnswers, 0));
    await this.scorm.setBaseData("scoreScaled", Math.max(numberOfRightAnswers / quiz.questionList.length, 0));
  };

  resetFinalQuizAnswerResult = () => {
    this.store.quizResults = {};
  };
}

export { Core };
