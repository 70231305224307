import React, { useState, ChangeEvent, useEffect } from "react";
import { Document, Page, Text, StyleSheet, PDFDownloadLink, Image, View, Font } from "@react-pdf/renderer";
import TextField from "@material-ui/core/TextField";
import { IdentityForm, ValidateButton } from "./Certificate.style";
import moment from "moment";
import { StandardButton } from "..";

interface Props {
  password?: string;
  onSubmitIdentity?: (identity: IdentityFields) => any;
  backgroundImage: string;
  formationName: string;
  companyLogo: string;
  formationLogo: string;
  primaryColor: string;
  secondaryColor: string;
}

type IdentityFields = { firstName: string; lastName: string };
type Step = "password" | "identity" | "certificate";

const CertificateInput: React.FC<Props> = (props) => {
  const [step, setStep] = useState<Step>(props.password ? "password" : "identity");
  const [password, setPassword] = useState("");
  const [identity, setIdentyFields] = useState({ firstName: "", lastName: "" });

  useEffect(() => {
    if (password === props.password) setStep("identity");
  }, [password]);

  const onChangePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
  };

  const onChangeIdentityFields = (field: keyof IdentityFields) => (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIdentyFields({ ...identity, [field]: event.target.value });
  };
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (props.onSubmitIdentity) props.onSubmitIdentity(identity);
    setStep("certificate");
    event.preventDefault();
  };

  return (
    <div>
      {step === "password" && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField label={"Mot de passe"} value={password} onChange={onChangePassword} />
        </div>
      )}
      {step === "identity" && (
        <IdentityForm onSubmit={onSubmit}>
          <TextField label={"Prénom"} value={identity.firstName} onChange={onChangeIdentityFields("firstName")} />
          <TextField label={"Nom"} value={identity.lastName} onChange={onChangeIdentityFields("lastName")} />
          <StandardButton style={{ marginTop: "1em" }} type="submit">
            {"Valider"}
          </StandardButton>
        </IdentityForm>
      )}
      {step === "certificate" && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
          <PDFDownloadLink
            style={{ display: "flex", flexDirection: "column" }}
            document={generateDoc({
              firstName: identity.firstName,
              lastName: identity.lastName,
              backgroundImage: props.backgroundImage,
              companyLogo: props.companyLogo,
              formationLogo: props.formationLogo,
              formationName: props.formationName,
              primaryColor: props.primaryColor,
              secondaryColor: props.secondaryColor,
            })}
            fileName={"certificate.pdf"}
          >
            {({ blob, url, loading, error }) => {
              return <ValidateButton>{"Télécharger le certificat"}</ValidateButton>;
            }}
          </PDFDownloadLink>
        </div>
      )}
    </div>
  );
};

const generateDoc = (props: {
  firstName: string;
  lastName: string;
  backgroundImage: string;
  formationName: string;
  companyLogo: string;
  formationLogo: string;
  primaryColor: string;
  secondaryColor: string;
}) => {
  Font.register({
    family: "Merriweather",
    fonts: [
      { src: process.env.PUBLIC_URL + "/assets/certificate-fonts/Merriweather-Regular.ttf" },
      {
        src: process.env.PUBLIC_URL + "/assets/certificate-fonts/Merriweather-Bold.ttf",
        fontWeight: "bold",
      },
    ],
  });
  const docStyles = StyleSheet.create({
    body: {
      fontFamily: "Merriweather",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    backgroundImage: {
      position: "absolute",
      minWidth: "100%",
      minHeight: "100%",
      height: "100%",
      width: "100%",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      width: "70%",
      height: "80%",
      backgroundColor: "#FFFFFF",
    },
    logosContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      marginRight:"20%",
      paddingTop:"5%",
    },
    logoContainer: {
      flex: 1,
    },
    logo: {
      width: "15vw",
      height: "15vh",
    },
    certificateTitle: {
      fontSize: 60,
      color: props.primaryColor,
      fontWeight: "bold",
    },
    certificateUndertitle: {
      fontSize: 30,
      color: props.primaryColor,
      fontWeight: "bold",
      marginBottom: 5,
    },
    textContainer: {
      display: "flex",
      flexDirection: "row",
    },
    label: {
      color: props.secondaryColor,
      marginHorizontal: 10,
    },
    text: {
      color: props.secondaryColor,
      fontWeight: "demibold",
    },
    formationContainer: {
      backgroundColor: props.primaryColor,
      padding: 5,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "75%",
      paddingTop:10,
      paddingBottom:10,
      fontSize: 20,
      marginBottom:"2%",
    },
    formationIntro: {
      color: "#FFFFFF",
      fontSize: 15,
    },
    formationName: {
      color: "#FFFFFF",
      fontWeight: "bold",
    },
  });
  return (
    <Document>
      <Page style={docStyles.body} orientation="landscape" size="A4">
        <Image src={props.backgroundImage} style={docStyles.backgroundImage} />
        <View style={docStyles.card}>
          <View style={docStyles.logosContainer}>
            <View style={docStyles.logoContainer}>
              <Image style={docStyles.logo} src={props.companyLogo} />
            </View>
            <View style={docStyles.logoContainer}>
              {/*<Image style={docStyles.logo} src={props.formationLogo} />*/}
            </View>
          </View>
          <View style={{ display: "flex", alignItems: "center" }}>
            <Text style={docStyles.certificateTitle}>{"CERTIFICAT"}</Text>
            <Text style={docStyles.certificateUndertitle}>{"DE FORMATION"}</Text>
          </View>
          <Text style={docStyles.label}>{"Ce certificat de formation est attribué à :"}</Text>
          <View style={docStyles.textContainer}>
            <Text style={docStyles.label}>{"Nom :"}</Text>
            <Text style={docStyles.text}>{props.lastName}</Text>
          </View>
          <View style={docStyles.textContainer}>
            <Text style={docStyles.label}>{"Prénom :"}</Text>
            <Text style={docStyles.text}>{props.firstName}</Text>
          </View>
          <View style={docStyles.textContainer}>
            <Text style={docStyles.label}>{"Date :"}</Text>
            <Text style={docStyles.text}>{moment().format("DD/MM/yyyy")}</Text>
          </View>
          <View style={docStyles.formationContainer}>
            <Text style={docStyles.formationIntro}>{"POUR LA FORMATION"}</Text>
            <Text style={docStyles.formationName}>{props.formationName}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CertificateInput;
