import React from "react";
import { H1, H2 } from "./Title.style";

interface TitleInterface {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  fontColor?: string;
  customClass?: string;
}

const Title: React.FC<TitleInterface> = (props) => {
  if (props.level === 1) {
    return (
      <H1 fontColor={props.fontColor} className={`title_level_${props.level} ${props.customClass || ""} `}>
        {props.children}
      </H1>
    );
  }
  if (props.level === 2) {
    return (
      <H2 fontColor={props.fontColor} className={`title_level_${props.level} ${props.customClass || ""}`}>
        {props.children}
      </H2>
    );
  }

  return null;
};

export default Title;
