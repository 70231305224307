import { styled } from "../../theme";
import { pickTextColorBasedOnBgColor } from "../../utils";
import { MediaImage } from "../../types";

export const FooterStyle = styled.footer<{ bg?: MediaImage | string; hidden?: boolean }>`
  width: 100%;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  min-height: 10vh;
  max-height: 10vh;
  background: ${({ bg }) => (bg ? (typeof bg === "string" ? bg : `url("${bg.url}")`) : "none")};
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    min-height: ${({ theme }) => theme.footer.mobileHeight};
    flex-direction: column;
  }
`;

export const FooterContainerStyle = styled.ul`
  padding-inline-start: 0;
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: rows;
  list-style-type: none;
  margin: 0;
  align-items: center;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    width: 100%;
    height: 100%;
  }
`;

export const FooterLeftContainerStyle = styled(FooterContainerStyle)`
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    flex-direction: column;
  }
`;
export const FooterRightContainerStyle = styled(FooterContainerStyle)`
  justify-content: flex-end;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    justify-content: space-evenly;
    padding: 0.5em;
  }
`;

export const FooterLeftItemStyle = styled.li`
  padding-right: 1em;
  padding-left: 1em;
  min-width: 20%;
  font-weight: bold;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    &:first-of-type {
      padding-top: 1em;
    }
    padding-bottom: 1em;
  }
`;

export const FooterRightItemStyle = styled.li`
  color: white;
  padding-right: 1em;
  min-width: 15%;
  max-height: calc(${({ theme }) => theme.footer.height} - 1em);
  box-sizing: border-box;
  img {
    max-height: calc(${({ theme }) => theme.footer.height} - 1em);
  }
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    padding-bottom: 1em;
    padding-right: 0;
  }
`;

export const FooterLeftItemModalStyle = styled.button`
  text-decoration: none;
  text-align: center;
  display: block;
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  color: ${({ theme }) => theme.color.lightText};
  &:hover {
    text-decoration: underline;
    box-sizing: border-box;
  }
`;

export const FooterLeftItemLinkStyle = styled.a`
  text-decoration: none;
  text-align: center;
  display: block;
  cursor: pointer;
  color: ${({ theme }) => theme.color.lightText};
  &:hover {
    text-decoration: underline;
    box-sizing: border-box;
  }
`;


export const FooterRightItemLinkStyle = styled.a`
  text-decoration: none;
  cursor: pointer;
  &:visited {
    color: white;
  }
  &:hover {
    font-weight: bold;
    box-sizing: border-box;
  }
`;

export const ContainerInModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
