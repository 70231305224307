import { computed, observable } from "mobx";
import { Content, Box } from "../types";
import { AnswerResult, QuestionAggregate } from "@upandgo/react-quiz";

class Store {
  @observable
  content: Content;

  @observable
  currentBox?: Box;

  @computed
  get isFullScreen(): boolean {
    return (
      this.currentBox !== undefined && this.currentBox.type === "content" && this.currentBox.fullscreen !== undefined
    );
  }

  @observable
  isFirstTime = false;

  @observable
  successStatus: "passed" | "failed" | "unknown" = "unknown";

  @observable
  completionStatus: "completed" | "incomplete" | "not attempted" | "unknown" = "unknown";

  @observable
  objectiveList: {
    id: string;
    completionStatus: "completed" | "incomplete" | "not attempted" | "unknown";
    successStatus: "passed" | "failed" | "unknown";
  }[] = [];

  @observable
  interactionList: { id: string; result: "correct" | "incorrect" | "unanticipated" | "neutral" }[] = [];

  @observable
  quizResults: {
    [key: string]: {
      quiz: QuestionAggregate;
      successStatus: "passed" | "failed" | "unknown";
      answerList: AnswerResult[];
      numberOfRightAnswers: number;
    };
  } = {};

  constructor(contentFile: Content) {
    this.content = contentFile;
  }
}

export { Store };
