import React, { useContext } from "react";
import { ActivityText2 } from "../../types";
import { Title, StandardButton } from "../";
import { BaseActivityProps } from "../Activity/Activity.component";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { Container, TextTitleContainer } from "./ActivityText2.style";
import texts from "../../texts";

interface Props extends BaseActivityProps {
  activity: ActivityText2;
}

const ActivityText2Component: React.FC<Props> = (props) => {
  const { core } = useContext(StoreContext);
  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  if (props.activity.tracking?.successCondition === "open") {
    core.setInteractionResult(props.activity.id, "correct");
  }
  return (
    <Container>
      {props.activity.title && (
        <TextTitleContainer className="text-title-container">
          <Title level={2}>{props.activity.title}</Title>
        </TextTitleContainer>
      )}
      <div dangerouslySetInnerHTML={{ __html: props.activity.text }} />
      {props.goNext && (
        <StandardButton onClick={onValidate}>
          {props.nextBehaviour === "close" ? texts.close : texts.next}
        </StandardButton>
      )}
    </Container>
  );
};

export default observer(ActivityText2Component);
