import React, { useContext } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import { ActivityVideo } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import { Container, VideoContainer, VideoTitleContainer } from "./ActivityVideo.style";
import { MediaContainer, StandardButton, Title } from "..";
import texts from "../../texts";

interface Props extends BaseActivityProps {
  activity: ActivityVideo;
}

const ActivityVideoContainer: React.FC<Props> = (props) => {
  const { core } = useContext(StoreContext);
  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  if (props.activity.tracking?.successCondition === "open") {
    core.setInteractionResult(props.activity.id, "correct");
  }
  if (props.activity.tracking?.successCondition === "end") {
    //TODO: getVideo End
    core.setInteractionResult(props.activity.id, "correct");
  }
  return (
    <Container className="main-video-container">
      {props.activity.title && (
        <VideoTitleContainer className="video-title-container">
          <Title level={2}>{props.activity.title}</Title>
        </VideoTitleContainer>
      )}
      <VideoContainer className="video-container">
        <MediaContainer media={props.activity.video} />
      </VideoContainer>
      {props.goNext && (
        <StandardButton onClick={onValidate}>
          {props.nextBehaviour === "close" ? texts.close : texts.next}
        </StandardButton>
      )}
    </Container>
  );
};

export default observer(ActivityVideoContainer);
