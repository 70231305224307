import { styled } from "../../theme";

interface ColumnContainerProps {
  reverse?: boolean;
}
export const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  height: 100%;
`;
export const ColumnContainer = styled.div<ColumnContainerProps>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 90%;
  .rich-text-container {
    font-size: 1.4em;
  }
`;

export const Column = styled.div`
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  max-height: 100%;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;
export const MultimediaTitleContainer = styled.div``;
