import React, { useRef, useState, useEffect } from "react";
import { Title, BoxList, StandardCloseButton } from "../index";
import { core, store } from "../../state";
import { useHistory } from "react-router-dom";
import {
  SubMenuStyle,
  SubMenuOverlay,
  SubMenuContainerStyle,
  SubMenuTitleContainerStyle,
  SubMenuObjectiveContainerStyle,
  SubMenuObjectiveTextStyle,
  SubMenuSectionStyle,
  SubMenuBoxItemContainerStyle,
  CloseButtonContainer,
} from "./SubMenu.style";
import { Media, MediaImage, MenuBox } from "../../types";

interface Props {
  bg: MediaImage | string;
  parentBox: MenuBox;
}

const SubMenu: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [boxWidth, setBoxWidth] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (boxWidth === 0) {
      setBoxWidth(ref.current ? ref.current.offsetHeight : 0);
    }
  }, [ref]);
  const onClickClose = () => {
    if (store.currentBox) {
      const parentBox = core.getBoxParent(store.currentBox.id, store.content.boxList);
      history.push(parentBox ? `/box?idBox=${parentBox.id}` : "/");
    }
  };

  return (
    <>
      {props.parentBox.overlayColor && <SubMenuOverlay overlayColor={props.parentBox.overlayColor} />}
      {(props.bg as Media) && (
        <SubMenuStyle bg={props.bg} className={"submenu"}>
          <SubMenuSectionStyle className={"submenu-section"}>
            <SubMenuContainerStyle
              bg={props.parentBox.menuBackground}
              autoWidth={boxWidth}
              ref={ref}
              className={"submenu-container"}
            >
              <CloseButtonContainer className="sub-menu-close-button-container">
                <StandardCloseButton onClick={onClickClose} />
              </CloseButtonContainer>
              <SubMenuTitleContainerStyle className={"submenu-title-container"}>
                <Title
                  customClass={"submenu-title"}
                  fontColor={props.parentBox.titleColor || props.parentBox.primaryColor}
                  level={2}
                >
                  {props.parentBox.title}
                </Title>
              </SubMenuTitleContainerStyle>
              {props.parentBox.objectiveText && (
                <SubMenuObjectiveContainerStyle className={"submenu-objective-container"}>
                  <SubMenuObjectiveTextStyle className={"submenu-objective"}>
                    {props.parentBox.objectiveText}
                  </SubMenuObjectiveTextStyle>
                </SubMenuObjectiveContainerStyle>
              )}
              <SubMenuBoxItemContainerStyle className={"submenu-boxlist-container"}>
                <BoxList boxList={props.parentBox.boxList}></BoxList>
              </SubMenuBoxItemContainerStyle>
            </SubMenuContainerStyle>
          </SubMenuSectionStyle>
        </SubMenuStyle>
      )}
    </>
  );
};

export default SubMenu;
