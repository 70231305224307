import React, { useContext } from "react";
import { Title } from "../index";
import {
  BoxItemStyle,
  BoxItemTitleContainerStyle,
  BoxItemIconContainerStyle,
  BoxItemIconStyle,
  BoxItemVisitedBadgeStyle,
  BoxItemConnector,
  BoxItemLockedBadgeStyle,
} from "./BoxItem.style";
import { Box } from "../../types";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";

interface Props {
  box: Box;
  linkTo?: string;
}
const BoxItem: React.FC<Props> = (props) => {
  const { core } = useContext(StoreContext);
  const status = core.isBoxComplete(props.box.id) ? "visited" : core.isBoxLocked(props.box.id) ? "locked" : undefined;
  return (
    <BoxItemStyle
      to={props.linkTo && status !== "locked" ? props.linkTo : "#"}
      style={{ textDecoration: "none" }}
      id={props.box.id}
      className={`box-item ${props.box.type === "empty" ? "disabled" : ""}`}
      bg={props.box.boxBackground}
    >
      {props.box.title && (
        <BoxItemTitleContainerStyle className={"box-item-title-container"}>
          <Title customClass={"box-item-title"} fontColor={props.box.primaryColor} level={2}>
            {props.box.title}
          </Title>
        </BoxItemTitleContainerStyle>
      )}
      {props.box.icon && (
        <BoxItemIconContainerStyle className={"box-item-icon-container"}>
          <BoxItemIconStyle bg={props.box.icon} className={"box-item-icon"} />
        </BoxItemIconContainerStyle>
      )}
      {status === "visited" && <BoxItemVisitedBadgeStyle className={"box-item-badge badge-visited"} />}
      {status === "locked" && <BoxItemLockedBadgeStyle className={"box-item-badge badge-locked"} />}
      {props.box.boxConnectionList &&
        props.box.boxConnectionList.map((connector, index) => (
          <BoxItemConnector key={index} className={`direction_${connector}`} />
        ))}
    </BoxItemStyle>
  );
};

export default observer(BoxItem);
