import { styled } from "../../theme";
import { MediaImage } from "../../types";

export const Background = styled.div<{ bg: string | MediaImage }>`
  min-width: 100%;
  min-height: 100%;
  ${({ bg }) => (typeof bg === "string" ? `background-color: ${bg}` : `background-image: url(${bg.url})`)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  z-index: -2;
`;

export const VideoBackgroundContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
`;
export const CarouselBackgroundContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
`;

type VideoBackgroundProps = {
  computedWidth: number;
  computedHeight: number;
  containerWidth: number;
  containerHeight: number;
};

export const VideoBackground = styled.video<VideoBackgroundProps>`
  position: absolute;
  z-index: -2;
  top: ${({ computedHeight, containerHeight }) => `-${(computedHeight - containerHeight) / 2}px`};
  left: ${({ computedWidth, containerWidth }) => `-${(computedWidth - containerWidth) / 2}px`};
  width: ${({ computedWidth }) => `${computedWidth}px`};
  height: ${({ computedHeight }) => `${computedHeight}px`};
`;

export const BackgroundChildrenContainer = styled.div`
  min-width: 100%;
  position: relative;
  min-height: 100%;
  height: 100%;
  z-index: 10;
`;

