import React, { useContext, useEffect } from "react";
import { BaseActivityProps } from "../Activity/Activity.component";
import Title from "../Title/Title.component";
import { ActivityQuiz } from "../../types";
import QuizView, { AnswerResult, isLastQuestion, QuestionAggregate } from "@upandgo/react-quiz";
import { Container, QuizTitleContainer } from "./ActivityQuiz.style";
import { theme } from "../../theme";
import texts from "../../texts";
import { StoreContext } from "../../context";
import deepClone from "lodash/cloneDeep";
import { toJS } from "mobx";

interface Props extends BaseActivityProps {
  activity: ActivityQuiz;
}

const ActivityQuizComponent: React.FC<Props> = (props) => {
  const { store, core } = useContext(StoreContext);
  useEffect(() => {
    core.resetFinalQuizAnswerResult();
  }, []);
  const onAnswerResult = async (result: AnswerResult) => {
    if (store.currentBox) {
      await core.setFinalQuizAnswerResult({
        quiz: props.activity.quiz,
        answer: result,
        threshold: props.activity.validatingThreshold || 0,
      });
      if (isLastQuestion(props.activity.quiz, result.idQuestion) && props.goNext) {
        props.goNext();
      }
    }
  };
  return (
    <Container className="quiz-main-container">
      {props.activity.title && (
        <QuizTitleContainer className="quiz-title-container">
          <Title level={2}>{props.activity.title}</Title>
        </QuizTitleContainer>
      )}
      <QuizView
        // To force Quiz update
        theme={{
          primaryColor: theme.color.primary,
          secondaryColor: theme.color.secondary,
          textColor: theme.color.text,
          textAnswerColor: theme.color.text,
          validColor: theme.color.valid,
          invalidColor: theme.color.invalid,
        }}
        onAnswerQuestion={onAnswerResult}
        questionAggregate={{
          ...deepClone(toJS(props.activity.quiz)),
          text: { next: texts.next, validate: texts.validate },
        }}
      />
    </Container>
  );
};

export default ActivityQuizComponent;
