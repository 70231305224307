import { styled } from "../../theme";

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StorylineIframe = styled.iframe`
  border: none;
  box-sizing: border-box;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const StorylineTitleContainer = styled.div``;
