import React, { useContext } from "react";
import { ActivityText1 } from "../../types";
import { Container, TextContainer, TextColumn, TextTitleContainer } from "./ActivityText1.style";
import { BaseActivityProps } from "../Activity/Activity.component";
import { StandardButton, Title } from "..";
import { observer } from "mobx-react";
import { StoreContext } from "../../context";
import texts from "../../texts";

interface Props extends BaseActivityProps {
  activity: ActivityText1;
}

const ActivityText1Component: React.FC<Props> = (props) => {
  const { core } = useContext(StoreContext);
  const onValidate = () => {
    if (props.goNext) props.goNext();
  };
  if (props.activity.tracking?.successCondition === "open") {
    core.setInteractionResult(props.activity.id, "correct");
  }
  return (
    <Container>
      {props.activity.title && (
        <TextTitleContainer className="text-title-container">
          <Title level={2}>{props.activity.title}</Title>
        </TextTitleContainer>
      )}
      <TextContainer className="text-activity-container">
        <TextColumn className="text-activity-column" dangerouslySetInnerHTML={{ __html: props.activity.textLeft }} />
        <TextColumn className="text-activity-column" dangerouslySetInnerHTML={{ __html: props.activity.textRight }} />
      </TextContainer>
      {props.goNext && (
        <StandardButton onClick={onValidate}>
          {props.nextBehaviour === "close" ? texts.close : texts.next}
        </StandardButton>
      )}
    </Container>
  );
};

export default observer(ActivityText1Component);
