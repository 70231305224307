import { styled } from "../../theme";
import { MediaImage } from "../../types";

export const Container = styled.div<{ bg?: MediaImage | string }>`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  background: ${({ bg }) => (bg ? (typeof bg === "string" ? bg : `url("${bg.url}")`) : "none")};
  background-size: cover;
  background-position: center;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    padding-bottom: 5em;
    box-sizing: border-box;
    max-height: initial;
    height: initial;
  }
`;
