import { styled } from "../../theme";

export const BoxListStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    flex-direction: column;
    height: auto;
  }
`;
export const BoxListItemContainer = styled.div<{ hideMobile: boolean; autoHeight: number }>`
  max-width: 30%;
  min-width: 30%;
  height: ${({ autoHeight }) => `${autoHeight}%`};
  min-height: ${({ autoHeight }) => `${autoHeight}%`};
  @media (max-width: ${({ theme }) => theme.mobileFirst.breakPoint}) {
    display: ${({ hideMobile }) => (hideMobile ? "none" : "block")};
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    height: inherit;
    margin-bottom: 1em;
  }
`;
