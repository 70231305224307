import { Content } from "./types";

const homePageUrl = process.env.PUBLIC_URL;

const asset = (url: string) => homePageUrl + url;

const getContent = (lang: "fr"): Content => {
  return {
    idApplication: "5f59027111a2416a4371df0e",
    title: "Plus de <b>80 ans</b> de savoir-faire artisanal",
    language: "fr",
    backgroundMedia: [
      {
        type: "image",
        url: asset("/assets/content/background/home-bg1.jpg"),
      },
      {
        type: "image",
        url: asset("/assets/content/background/home-bg2.jpg"),
      },
      {
        type: "image",
        url: asset("/assets/content/background/home-bg3.jpg"),
      },
    ],
    footerLeft: [{ id: "legals", text: "Mentions légales", url: asset("/assets/content/resources/HENRI_RAFFIN_MentionsLegales.pdf") }],
    footerRight: [],
    footerBackground: {
      type: "image",
      url: asset("/assets/content/background/raffin-digibox-footer.png"),
    },
    logo: asset("/assets/content/logo-henri-raffin.png"),
    boxList: [
      {
        id: "box1",
        type: "content",
        boxBackground: {
          type: "image",
          url: asset("/assets/content/background/bg_box_red.jpg"),
        },
        background: {
          type: "image",
          url: asset("/assets/content/background/bg-activity-white.jpg"),
        },
        order: 0,
        title: "Qui sommes-nous ?",
        activityList: [
          {
            type: "video",
            id: "box1-presentation",
            video: {
              type: "video",
              url: asset("/assets/content/videos/box1_presentation.mp4"),
            },
          },
        ],
        primaryColor: "#FFFFFF",
        secondaryColor: "#59372F",
      },
      {
        id: "box2",
        type: "menu",
        boxBackground: "#59372F",
        order: 0,
        title: "Le saucisson",
        overlayColor: "#211611",
        background: {
          type: "image",
          url: asset("/assets/content/background/home-bg3.jpg"),
        },
        menuBackground: {
          type: "image",
          url: asset("/assets/content/background/bg_digibox-submenu.png"),
        },
        boxList: [
          {
            type: "content",
            id: "box2-1",
            boxBackground: {
              type: "image",
              url: asset("/assets/content/background/bg_box_red.jpg"),
            },
            title: "Pour débuter",
            icon: asset("/assets/content/box-icon/picto_begin.png"),
            primaryColor: "#FFFFFF",
            secondaryColor: "",
            background: {
              type: "image",
              url: asset("/assets/content/background/bg_digibox-submenu.png"),
            },
            order: 0,
            activityList: [
              {
                embedNavigation: true,
                type: "multimedia1",
                title: "Pour débuter",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box2-1-pour-debuter-intro",
                text: `<p>Le saucisson est un met tr&egrave;s ancien puisque nous en avons retrouv&eacute; des recettes datant de l&#39;empire romain.</p>
              <p>Eh oui ! A l&#39;&eacute;poque, ils ma&icirc;trisaient d&eacute;j&agrave; la conservation du porc et la salaison.&nbsp;</p>                              
              <p>En latin, &quot;salsus&quot; signifie &quot;sal&eacute;&quot;, qui donnera son nom au saucisson.&nbsp;</p> 
              <p>Il faudra cependant attendre 1546 pour voir appara&icirc;tre le mot &quot;saucisson&quot; en France dans &quot;Tiers Livre&quot; de Rabelais.&nbsp;</p>         
              <p>Aujourd&#39;hui, le saucisson est devenu l&#39;indispensable des pique-niques et des ap&eacute;ritifs.</p>
              `,
                media: {
                  type: "image",
                  url: asset("/assets/content/box2/pour-debuter-intro.jpg"),
                },
              },
              {
                embedNavigation: true,
                title: "Pour débuter",
                type: "fakeQuestion",
                id: "box2-1-saucisson-Q1",
                question: "Combien de temps sont affinés nos saucissons ?",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                answerList: [
                  {
                    text: "18 jours",
                  },
                  {
                    text: "4 semaines",
                  },
                ],
              },
              {
                id: "box2-1-saucisson-Debrief-Q1",
                title: "Pour débuter",
                embedNavigation: true,
                type: "multimedia2",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                media: {
                  type: "image",
                  url: asset("/assets/content/box2/box2-1-saucisson-Debrief-Q1.jpg"),
                },
                text: `<p>Nos saucissons restent 4 semaines en cellule d'affinage, soit une semaine de plus que la plupart des saucissons du marché. </p>
                <p>Chez Henri Raffin, nous prenons notre temps pour permettre à la viande de bien développer ses arômes et ainsi proposer des saucissons sans arômes ajoutés pour les gammes : Savoie, à l'Ancienne et Artisanale.</p>`,
              },
              {
                type: "fakeQuestion",
                id: "box2-1-saucisson-Q2",
                title: "Pour débuter",
                embedNavigation: true,
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                question: "Quelle part des ventes représente le saucisson nature ?",
                answerList: [
                  {
                    text: "moins de la moitié",
                  },
                  {
                    text: "plus de la moitié",
                  },
                ],
              },
              {
                id: "box2-1-saucisson-Debrief-Q2",
                type: "multimedia2",
                title: "Pour débuter",
                media: {
                  type: "image",
                  url: asset("/assets/content/box2/box2-1-saucisson-Debrief-Q2.jpg"),
                },
                embedNavigation: true,
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                text: `<p style="text-align: left;">Plus de la moiti&eacute;!&nbsp;</p>
                <p style="text-align: left;">Les saucissons <strong>natures</strong> repr&eacute;sentent &agrave; eux seuls <strong>85 %</strong> du march&eacute; dans de multiples formats (rosette, droite, court, grand format, etc.).</p>
                <p style="text-align: left;">Les sp&eacute;cialit&eacute;s repr&eacute;sentent quant &agrave; elles les derniers 15 %. Dans ce segment, <strong>Henri Raffin est super leader</strong> (26,4% en PDM valeur) avec des inclusions de morceaux <strong>entiers</strong> et <strong>nobles</strong> sans aucun ar&ocirc;me artificiel.</p>`,
              },
              {
                type: "fakeQuestion",
                embedNavigation: true,
                title: "Pour débuter",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box2-1-saucisson-Q3",
                question: "L’ensemble du processus de fabrication du saucisson est totalement automatisé.",
                answerList: [
                  {
                    text: "Vrai",
                  },
                  {
                    text: "Faux",
                  },
                ],
              },
              {
                embedNavigation: true,

                id: "box2-1-saucisson-Debrief-Q3",
                type: "multimedia2",
                title: "Pour débuter",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                media: {
                  type: "image",
                  url: asset("/assets/content/box2/box2-1-saucisson-Debrief-Q3.jpg"),
                },
                text: `<p style="text-align: left;">Faux : Lors de la fabrication du saucisson, les machines sont l&agrave; en soutient des agents de production, mais ne prennent en aucun cas leur place. En effet, le regard humain est essentiel pour nous assurer de la qualit&eacute; du produit.</p>

                <p style="text-align: left;">Nous avons des machines de haute qualit&eacute; nous permettant de garantir une homog&eacute;n&eacute;it&eacute; des produits. Cette facilit&eacute; apport&eacute;e par des m&eacute;canismes, offre plus de possibilit&eacute;s aux op&eacute;rateurs afin de s&eacute;lectionner les pi&egrave;ces pr&ecirc;tes &agrave; la consommation.</p>
                `,
              },
            ],
          },
          {
            type: "content",

            id: "box2-2",
            boxBackground: "#59372F",
            title: "La fabrication",
            icon: asset("/assets/content/box-icon/picto_play.png"),
            primaryColor: "#FFFFFF",
            secondaryColor: "",
            background: {
              type: "image",
              url: asset("/assets/content/background/bg_digibox-submenu.png"),
            },
            order: 1,
            activityList: [
              {
                type: "video",
                id: "box2-saucisson",
                video: {
                  type: "video",
                  url: asset("/assets/content/videos/box2_saucisson.mp4"),
                },
              },
            ],
          },
          {
            type: "content",

            id: "box2-3",
            boxBackground: {
              type: "image",
              url: asset("/assets/content/background/bg_box_beige.jpg"),
            },
            title: "En savoir plus",
            icon: asset("/assets/content/box-icon/picto_info_red.png"),
            primaryColor: "#c52300",
            secondaryColor: "",
            background: {
              type: "image",
              url: asset("/assets/content/background/bg_digibox-submenu.png"),
            },
            order: 0,
            activityList: [
              {
                type: "multimedia1",
                title: "En savoir plus",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box2-3-En-savoir-plus-1",
                media: {
                  type: "image",
                  url: asset("/assets/content/box2/box2-3-En-savoir-plus-1.jpg"),
                },
                text: `<p style="text-align: left;">Il existe diff&eacute;rents types de boyaux pour embosser les saucissons :</p>

                <ol>
                  <li style="text-align: left;">Les <strong>boyaux naturels de porc </strong>existant sous diff&eacute;rents calibres (le menu, le sac, le fuseau, le suivant et le chaudin).</li>
                  <li style="text-align: left;">Les <strong>boyaux collag&eacute;niques en fibres animales</strong> (collag&egrave;ne) se rapprochant des boyaux naturels. Fabriqu&eacute;s industriellement, ils ont un calibre et une qualit&eacute; identiques.</li>
                  <li style="text-align: left;">Les <strong>boyaux cellulosiques</strong> constitu&eacute;s de papier sp&eacute;cial, noy&eacute; dans la cellulose reg&eacute;n&eacute;r&eacute;e.</li>
                </ol>
                
                <p style="text-align: left;"><strong>Alginate de Sodium, cellulose ou collag&egrave;ne</strong> : constitu&eacute;s d&#39;algues, ils servent de g&eacute;lifiant ou d&#39;enveloppe (boyau)</p>
                
              `,
              },
              {
                type: "multimedia1",
                title: "En savoir plus",
                media: {
                  type: "image",
                  url: asset("/assets/content/box2/box2-3-En-savoir-plus-2.png"),
                },
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box2-3-En-savoir-plus-2",
                text: `<p style="text-align: left;">Notre <strong>saucisson sec sup&eacute;rieur</strong> est r&eacute;alis&eacute; &agrave; partir de viande fra&icirc;che, avec un boyau et une fleur naturels.</p>

                <p style="text-align: left;">Notre <strong>saucisson sec &agrave; l&rsquo;Ancienne</strong> est, en plus des caract&eacute;ristiques de la qualit&eacute; sup&eacute;rieure, garanti sans ar&ocirc;me artificiel ni nitrite et le boyau est ferm&eacute; &agrave; la ficelle.</p>
                
                <p style="text-align: left;">Notre <strong>saucisson sec artisanal </strong>est, en plus des caract&eacute;ristiques de la qualit&eacute; &agrave; l&rsquo;Ancienne, fabriqu&eacute; dans un atelier artisanal et affin&eacute; en s&eacute;choir en bois. Il est garanti sans lactose et r&eacute;alis&eacute; &agrave; partir d&rsquo;&eacute;paule de porc.</p>                
              `,
              },
              {
                type: "multimedia2",
                title: "En savoir plus",

                media: {
                  type: "image",
                  url: asset("/assets/content/box2/box2-3-En-savoir-plus-3.jpg"),
                },
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box2-3-En-savoir-plus-3",
                text: `<p style="text-align: left;">En savoir plus sur la <strong>fleur :</strong></p>
                <p style="text-align: left;">La fleur externe d&eacute;signe l&rsquo;ensemble des micro-organismes qui se d&eacute;veloppent &agrave; la surface des boyaux des saucissons secs. Chez Henri Raffin nous utilisons uniquement la fleur appel&eacute;e &laquo; p&eacute;nicillium &raquo; : blanche et naturelle. Elle donne un go&ucirc;t particulier aux saucissons et elle &eacute;vite le d&eacute;veloppement de micro-organismes pouvant alt&eacute;rer la s&eacute;curit&eacute; bact&eacute;riologique du produit.&nbsp;</p>               
                <p style="text-align: left;">Certains de nos concurrents ajoutent &agrave; la fleur du talc, de la farine ou d&#39;autres produits pour garantir une couleur blanche au saucisson.</p>
              `,
              },
            ],
          },
        ],
        primaryColor: "#FFFFFF",
        secondaryColor: "#59372F",
      },
      {
        id: "box3",
        type: "empty",
        boxBackground: {
          type: "image",
          url: asset("/assets/content/background/box-jambon.jpg"),
        },
        order: 0,
        primaryColor: "#C50700",
        secondaryColor: "#59372F",
      },
      {
        id: "box4",
        type: "empty",
        boxBackground: {
          type: "image",
          url: asset("/assets/content/background/box-saucisson.jpg"),
        },
        order: 0,
        primaryColor: "#FFFFFF",
        secondaryColor: "#59372F",
      },
      {
        id: "box5",
        type: "menu",
        overlayColor: "#211611",
        boxBackground: {
          type: "image",
          url: asset("/assets/content/background/bg_box_red.jpg"),
        },
        order: 0,
        title: "La saucisserie",
        background: {
          type: "image",
          url: asset("/assets/content/background/home-bg3.jpg"),
        },
        menuBackground: {
          type: "image",
          url: asset("/assets/content/background/bg_digibox-submenu.png"),
        },
        boxList: [
          {
            type: "content",

            id: "box5-1",
            boxBackground: {
              type: "image",
              url: asset("/assets/content/background/bg_box_red.jpg"),
            },
            title: "Pour débuter",
            icon: asset("/assets/content/box-icon/picto_begin.png"),
            primaryColor: "#FFFFFF",
            secondaryColor: "",
            background: {
              type: "image",
              url: asset("/assets/content/background/bg_digibox-submenu.png"),
            },
            order: 0,
            activityList: [
              {
                type: "multimedia1",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                id: "box5-1-pour-débuter-intro",
                text: `<p style="text-align: left;">L&#39;histoire de la salaison, au m&ecirc;me titre que celle du fromage, trouve ses racines dans les r&eacute;gions montagneuses. Le climat et l&#39;altitude sont en effet des param&egrave;tres importants de la production. C&#39;est dans ce contexte que de nombreux produits sont n&eacute;s, comme le diot. R&eacute;alis&eacute; au d&eacute;part avec les restes de viandes et de l&eacute;gumes, il &eacute;tait mang&eacute; en pot&eacute;e. Aujourd&#39;hui r&eacute;alis&eacute; &agrave; partir de parties nobles du porc, c&#39;est une r&eacute;f&eacute;rence de la cuisine savoyarde, port&eacute;e sur le bon et le naturel.</p>
              `,
                media: {
                  type: "image",
                  url: asset("/assets/content/box5/pour-debuter-intro.jpg"),
                },
              },
              {
                type: "fakeQuestion",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                id: "box5-1-saucisserie-Q1",
                question: "Quel est le pourcentage d'eau consistuant nos diots ?",
                answerList: [
                  {
                    text: "Moins de 5 %",
                  },
                  {
                    text: "Entre 5 % et 10%",
                  },
                ],
              },
              {
                id: "box5-1-saucisserie-Debrief-Q1",
                type: "multimedia2",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                media: {
                  type: "image",
                  url: asset("/assets/content/box5/box5-1-saucisserie-Debrief-Q1.jpg"),
                },
                text: `<p style="text-align: left;"><strong>Moins de 5% ! </strong></p>

                <p style="text-align: left;">Nos diots sont compos&eacute;s &agrave; moins de 5% d&#39;eau, contrairement &agrave; la plupart des diots du march&eacute;. Reconnus pour leur qualit&eacute;, ils sont riches en viande, garantissant ainsi un go&ucirc;t plus intense et savoureux.&nbsp;</p>
                
                <p style="text-align: left;">Il n&#39;est pas obligatoire de mentionner l&#39;eau dans la composition des diots si elle repr&eacute;sente moins de 5% de la composition du produit. Nous sommes l&#39;un des seuls fabricants du march&eacute; dans ce cas. </p>
                `,
              },
              {
                type: "fakeQuestion",
                id: "box5-1-saucisserie-Q2",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                question: "Quel est le point commun entre les diots Natures x4 et les diots Fumés à l'ancienne x4",
                answerList: [
                  {
                    text: "Ce sont nos références phares",
                  },
                  {
                    text: "Ce sont les seuls diots vendus par 4",
                  },
                ],
              },
              {
                id: "box5-1-saucisserie-Debrief-Q2",
                type: "multimedia2",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                media: {
                  type: "image",
                  url: asset("/assets/content/box5/box5-1-saucisserie-Debrief-Q2.jpg"),
                },
                text: `<p style="text-align: left;">Les diots x4 Natures, au m&ecirc;me titre que les diots x4 Fum&eacute;s &agrave; l&#39;ancienne 340g sont les r&eacute;f&eacute;rences phares des diots chez Henri Raffin et ont leur place sur l&#39;ensemble du territoire national.</p>
                <p style="text-align: left;">Par ailleurs, la reconnaissance de notre <strong>qualit&eacute;</strong> au Concours G&eacute;n&eacute;ral Agricole chaque ann&eacute;e nous offre un v&eacute;ritable atout sur ce march&eacute;.</p>
                `,
              },
              {
                type: "fakeQuestion",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                id: "box5-1-saucisserie-Q3",
                question: "A quelle période de l'année se vend-il le plus de diots ?",
                answerList: [
                  {
                    text: "Avril - Octobre",
                  },
                  {
                    text: "Novembre - Mars",
                  },
                ],
              },
              {
                id: "box5-1-saucisserie-Debrief-Q3",
                type: "multimedia2",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                media: {
                  type: "image",
                  url: asset("/assets/content/box5/box5-1-saucisserie-Debrief-Q3.jpg"),
                },
                text: `<p style="text-align: left;"><strong>Novembre &agrave; Mars </strong></p>

                <p style="text-align: left;">Le diot est avant tout un plat d&eacute;gust&eacute; en hiver !&nbsp;</p>
                
                <p style="text-align: left;">C&#39;est pourquoi il se hisse au top des ventes sur la p&eacute;riode de novembre &agrave; mars et les taux de rotations sont multipli&eacute;s par 2.</p>                
                `,
              },
            ],
          },
          {
            type: "content",

            id: "box5-2",
            boxBackground: "#59372F",
            title: "La fabrication",
            icon: asset("/assets/content/box-icon/picto_play.png"),
            primaryColor: "#FFFFFF",
            secondaryColor: "",
            background: {
              type: "image",
              url: asset("/assets/content/background/bg_digibox-submenu.png"),
            },
            order: 1,
            activityList: [
              {
                type: "video",
                id: "box5-2-saucisserie",
                video: {
                  type: "video",
                  url: asset("/assets/content/videos/box5_saucisserie.mp4"),
                },
              },
            ],
          },
          {
            type: "content",

            id: "box5-3",
            boxBackground: {
              type: "image",
              url: asset("/assets/content/background/bg_box_beige.jpg"),
            },
            title: "En savoir plus",
            icon: asset("/assets/content/box-icon/picto_info_red.png"),
            primaryColor: "#c52300",
            secondaryColor: "",
            background: {
              type: "image",
              url: asset("/assets/content/background/bg_digibox-submenu.png"),
            },
            order: 0,
            activityList: [
              {
                type: "multimedia2",
                title: "En savoir plus",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box5-3-En-savoir-plus-1",
                media: {
                  type: "image",
                  url: asset("/assets/content/box5/box5-3-En-savoir-plus-1.jpg"),
                },
                text: `<p style="text-align: left;">Tout au long de l&#39;ann&eacute;e, nous proposons<strong>&nbsp;diverses gammes de diots</strong> !</p>
                <p style="text-align: left;">A chaque saison sa recette afin de ravir les papilles des consommateurs.</p>                
                <p style="text-align: left;">Pour l&#39;&eacute;t&eacute;, grill&eacute;s au barbecue, nous proposons les saveurs de thym de Provence ou piment doux.</p>              
                <p style="text-align: left;">Pour l&#39;hiver, cuisin&eacute;s en pot&eacute;e, ils se d&eacute;clinent en recettes g&eacute;n&eacute;reuses : reblochon, oignons &amp; vin blanc ou encore des recettes d&eacute;couvertes comme l&#39;Ail des ours.</p>
            `,
              },
              {
                type: "multimedia2",
                title: "En savoir plus",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                media: {
                  type: "image",
                  url: asset("/assets/content/box5/box5-3-En-savoir-plus-2.jpg"),
                },
                id: "box5-3-En-savoir-plus-2",
                text: `<p style="text-align: left;">Henri Raffin est le <strong>leader incontestable </strong>du march&eacute; du diot en France. Les diots Henri Raffin sont pr&eacute;sents dans toutes les r&eacute;gions. Avec <strong>45%</strong> des parts de march&eacute;, Henri Raffin est l&rsquo;incontournable du rayon.&nbsp;</p>
                <p style="text-align: left;">Nos Diots sont connus pour leur qualit&eacute;, nous avons &eacute;t&eacute; r&eacute;compens&eacute;s &agrave; de nombreuses reprises au Salon de l&#39;Agriculture.</p>       
                <p style="text-align: left;">En 2020, nos diots &agrave; l&rsquo;Ancienne fum&eacute;s ont obtenu la <strong>m&eacute;daille d&rsquo;or au Concours G&eacute;n&eacute;ral Agricole.</strong></p>                
              `,
              },
              {
                type: "multimedia2",
                title: "En savoir plus",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box5-3-En-savoir-plus-3",
                media: {
                  type: "image",
                  url: asset("/assets/content/box5/box5-3-En-savoir-plus-3.jpg"),
                },
                text: `<p>Reprenant la recette traditionnelle, nos diots sont fabriqu&eacute;s &agrave; partir de viande de porc fraiche fran&ccedil;aise. Emboss&eacute;s sous boyaux naturels nos diots sont garantis <strong>sans colorant</strong>.&nbsp;</p>

                <p>Par rapport &agrave; nos concurrents, nos produits ont une belle mati&egrave;re premi&egrave;re, moins d&#39;ingr&eacute;dients ajout&eacute;s, des taux de mati&egrave;res grasses et une teneur en eau plus faibles. Cette courte liste d&rsquo;ingr&eacute;dients (<strong>sans eau ni colorant artificiel</strong>) garantit au consommateur un produit de qualit&eacute;.</p>
                
              `,
              },
            ],
          },
        ],
        primaryColor: "#FFFFFF",
        secondaryColor: "#59372F",
      },
      {
        id: "box6",
        type: "menu",
        boxBackground: "#59372F",
        overlayColor: "#211611",
        order: 0,
        title: "Le jambon",
        background: {
          type: "image",
          url: asset("/assets/content/background/home-bg3.jpg"),
        },
        menuBackground: {
          type: "image",
          url: asset("/assets/content/background/bg_digibox-submenu.png"),
        },
        boxList: [
          {
            type: "content",

            id: "box6-1",
            boxBackground: {
              type: "image",
              url: asset("/assets/content/background/bg_box_red.jpg"),
            },
            title: "Pour débuter",
            icon: asset("/assets/content/box-icon/picto_begin.png"),
            primaryColor: "#FFFFFF",
            secondaryColor: "",
            background: {
              type: "image",
              url: asset("/assets/content/background/bg_digibox-submenu.png"),
            },
            order: 0,
            activityList: [
              {
                type: "multimedia1",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                media: {
                  type: "image",
                  url: asset("/assets/content/box6/pour-debuter-intro.jpg"),
                },
                id: "box6-1pour-debuter-intro",
                text: `<p style="text-align: left;">Le jambon est par d&eacute;finition le membre post&eacute;rieur du porc. Il existe diff&eacute;rents types de jambon : le jambon cru et le jambon sec. La diff&eacute;rence majeure entre ces deux jambons vient de l&#39;affinage. Un <strong>jambon cru</strong> sera affin&eacute; moins de <strong>4 mois</strong>, apr&egrave;s 4 mois on parlera de<strong>&nbsp;jambon sec</strong>.</p>
                <p style="text-align: left;">Chez Henri Raffin, nous produisons du <strong>jambon sec Sup&eacute;rieur de Savoie</strong>, pr&eacute;par&eacute; &agrave; partir d&#39;un jambon pesant 9kg minimum &agrave; la mise en sel. Il peut &ecirc;tre fum&eacute; au bois de h&ecirc;tre. </p> 
              `,
              },
              {
                type: "fakeQuestion",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                id: "box6-1-jambon-Q1",
                question: "En moyenne, combien de mois sont nécessaires à l'affinage de nos jambons ?",
                answerList: [
                  {
                    text: "7 mois",
                  },
                  {
                    text: "12 mois",
                  },
                  {
                    text: "20 mois",
                  },
                ],
              },
              {
                id: "box6-1-jambon-Debrief-Q1",
                type: "multimedia2",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                media: {
                  type: "image",
                  url: asset("/assets/content/box6/box6-1-jambon-Debrief-Q1.jpg"),
                },
                text: `<p style="text-align: left;">Nos jambons sont affin&eacute;s au minimum 9 mois et en <strong>moyenne 12 mois</strong> contre 7 mois pour nos concurrents. Cette p&eacute;riode d&rsquo;affinage offre une saveur et une qualit&eacute; sup&eacute;rieures &agrave; notre produit. Le temps est un &eacute;l&eacute;ment indispensable &agrave; la fabrication du jambon, il permet en effet de laisser au jambon la possibilit&eacute; de d&eacute;velopper lui-m&ecirc;me ses ar&ocirc;mes sans que nous ayons besoin d&rsquo;ajouter des exhausteurs de go&ucirc;t ou des ar&ocirc;mes. </p>

                `,
              },
              {
                type: "fakeQuestion",
                id: "box6-1-jambon-Q2",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                question: "Quel ingrédient n'est pas présent dans la composition de notre jambon ?",
                answerList: [
                  {
                    text: "La Saccharose",
                  },
                  {
                    text: "Le Sel",
                  },
                ],
              },
              {
                id: "box6-1-jambon-Debrief-Q2",
                type: "multimedia2",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                media: {
                  type: "image",
                  url: asset("/assets/content/box6/box6-1-jambon-Debrief-Q2.jpg"),
                },
                text: `<p style="text-align: left;">Nos Jambons secs de Savoie sont exclusivement compos&eacute;s de jambon de porc frais <strong>fran&ccedil;ais</strong>, de <strong>sel marin</strong> et de <strong>salp&ecirc;tre</strong> (nitrate de potassium).</p>
                `,
              },
              {
                type: "fakeQuestion",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                embedNavigation: true,
                id: "boxs-1-jambon-Q3",
                question: "Sur quel site de production nos jambons sont-ils produits ?",
                answerList: [
                  {
                    text: "La Rochette",
                  },
                  {
                    text: "Taninges ",
                  },
                ],
              },
              {
                id: "box6-1-jambon-Debrief-Q3",
                type: "multimedia2",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                media: {
                  type: "image",
                  url: asset("/assets/content/box6/box6-1-jambon-Debrief-Q3.jpg"),
                },
                text: `<p style="text-align: left;">Nos jambons sont produits sur le site artisanal de Taninges. C&rsquo;est au pied du Mont Blanc et &agrave; plus de 600 m&egrave;tres d&rsquo;altitude que sont fabriqu&eacute;s et affin&eacute;s nos jambons. &nbsp;C&rsquo;est en zone de montagne que les jambons secs &eacute;taient traditionnellement fabriqu&eacute;s. L&rsquo;atmosph&egrave;re et l&#39;altitude permettent de bonnes conditions de s&eacute;chage et un air sec pour une qualit&eacute; sup&eacute;rieure du produit. C&#39;est d&#39;ailleurs dans ce cadre que le site a &eacute;t&eacute; certifi&eacute; IFS de niveau sup&eacute;rieur.</p>             
                `,
              },
            ],
          },
          {
            type: "content",

            id: "box6-2",
            boxBackground: "#59372F",
            title: "La fabrication",
            icon: asset("/assets/content/box-icon/picto_play.png"),
            primaryColor: "#FFFFFF",
            secondaryColor: "",
            background: {
              type: "image",
              url: asset("/assets/content/background/bg_digibox-submenu.png"),
            },
            order: 1,
            activityList: [
              {
                type: "video",
                id: "box6-2-jambon",
                video: {
                  type: "video",
                  url: asset("/assets/content/videos/box6_jambon.mp4"),
                },
              },
            ],
          },
          {
            type: "content",

            id: "box6-3",
            boxBackground: {
              type: "image",
              url: asset("/assets/content/background/bg_box_beige.jpg"),
            },
            title: "En savoir plus",
            icon: asset("/assets/content/box-icon/picto_info_red.png"),
            primaryColor: "#c52300",
            secondaryColor: "",
            background: {
              type: "image",
              url: asset("/assets/content/background/bg_digibox-submenu.png"),
            },
            order: 0,
            activityList: [
              {
                type: "multimedia2",
                title: "En savoir plus",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box6-3-En-savoir-plus-1",
                media: {
                  type: "image",
                  url: asset("/assets/content/box6/box6-3-En-savoir-plus-1.jpg"),
                },
                text: `<p style="text-align: left;"><strong>Notre Valeur ajout&eacute;e !</strong></p>

                <p style="text-align: left;">Pour diff&eacute;rencier les jambons, il faut regarder en particulier le poids &agrave; la mise au sel ainsi que le<strong>&nbsp;temps d&#39;affinage</strong>. Ce sont ces deux param&egrave;tres qui permettent de caract&eacute;riser les jambons.</p>
                
                <p style="text-align: left;">Chez Henri Raffin nous travaillons depuis 2020 exclusivement avec des jambons frais <strong>origine France</strong> afin de garantir une <strong>qualit&eacute; sup&eacute;rieure de nos produits.</strong></p>
                
            `,
              },
              {
                type: "multimedia1",
                title: "En savoir plus",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box6-3-En-savoir-plus-2",
                media: {
                  type: "image",
                  url: asset("/assets/content/box6/box6-3-En-savoir-plus-2.jpg"),
                },
                text: `<p style="text-align: left;">Et la noix de jambon alors, qu&#39;est-ce que c&#39;est ?</p>

                <p style="text-align: left;">La <strong>noix de jambon </strong>est une partie sp&eacute;cifique du jambon. C&#39;est une partie noble du muscle du porc, qui est sal&eacute;e en baratte et assaisonn&eacute;e, puis emboss&eacute;e sous un boyau naturel appel&eacute; poche ou baudruche de porc, et enfin affin&eacute;e. Elle est soit ensemenc&eacute;e de p&eacute;nicillium soit fum&eacute;e au bois de h&ecirc;tre.</p>
                
                <p style="text-align: left;">La noix de jambon est une sp&eacute;cialit&eacute; Savoyarde.</p>
                                
              `,
              },
              {
                type: "multimedia2",
                title: "En savoir plus",
                background: {
                  type: "image",
                  url: asset("/assets/content/background/bg-activity-white.jpg"),
                },
                id: "box6-3-En-savoir-plus-3",
                media: {
                  type: "image",
                  url: asset("/assets/content/box6/box6-3-En-savoir-plus-3.jpg"),
                },
                text: `<p><p style="text-align: left;">Le lieu de production et d&#39;affinage &nbsp;agit grandement sur la qualit&eacute; du jambon. Chez Henri Raffin, nous avons un lieu de production <strong>artisanal</strong> &nbsp;et des s&eacute;choirs &agrave; 600 m d&#39;altitude, &agrave; Taninges. Ce village &nbsp;est, gr&acirc;ce &agrave; l&#39;air naturel des Alpes et le climat montagnard, le lieu id&eacute;al pour l&#39;affinage du jambon. Avec une mati&egrave;re premi&egrave;re de grande qualit&eacute; et une fabrication artisanale et manuelle, nous obtenons un jambon beau et go&ucirc;teux.</p>
                </p>
                
              `,
              },
            ],
          },
        ],
        primaryColor: "#FFFFFF",
        secondaryColor: "#59372F",
      },
      {
        id: "box7",
        type: "content",
        boxBackground: {
          type: "image",
          url: asset("/assets/content/background/bg_box_wood.jpg"),
        },
        background: {
          type: "image",
          url: asset("/assets/content/background/bg_digibox-submenu.png"),
        },
        order: 0,
        title: "Annexes",
        activityList: [
          {
            type: "resourceList",
            overlayColor: "#211611",
            id: "resourceList",
            resourceList: [
              {
                id: "INTRO_juin_book_de_vente",
                url: asset("/assets/content/resources/INTRO_juin_book_de_vente.pdf"),
                name: "INTRO juin book de vente",
                type: "pdf",
              },
              {
                id: "les_20-80",
                url: asset("/assets/content/resources/les_20-80.pdf"),
                name: "Les 20-80",
                type: "pdf",
              },
              {
                id: "Offre_festive_NOEL_2020_COUPE-FE",
                url: asset("/assets/content/resources/Offre_festive_NOEL_2020_COUPE-FE.pdf"),
                name: "Offre festive NOEL 2020 COUPE-FE",
                type: "pdf",
              },
              {
                id: "Offre_festive_NOEL_2020_COUPE-LS",
                url: asset("/assets/content/resources/Offre_festive_NOEL_2020_LS.pdf"),
                name: "Offre festive NOEL 2020 COUPE-LS",
                type: "pdf",
              },
            ],
          },
        ],
        primaryColor: "#FFFFFF",
        secondaryColor: "#59372F",
      },
      {
        id: "box8",
        type: "empty",
        boxBackground: {
          type: "image",
          url: asset("/assets/content/background/box-hachoir.jpg"),
        },
        order: 0,
        primaryColor: "#FFFFFF",
        secondaryColor: "#59372F",
      },
      {
        id: "box9",
        type: "content",

        boxBackground: {
          type: "image",
          url: asset("/assets/content/background/bg_box_wood.jpg"),
        },
        background: {
          type: "image",
          url: asset("/assets/content/background/home-bg3.jpg"),
        },
        /*objective: {
          successOnSucessOfInteractionList: ["quiz-final"],
        },*/
        order: 0,
        title: "Quiz",
        activityList: [
          {
            type: "realQuiz",
            id: "quiz-final",
            background: { type: "image", url: asset("/assets/content/background/bg-activity-yellow.jpg") },
            embedNavigation: true,
            tracking: {
              successCondition: "success",
            },
            validatingThreshold: 5,
            quiz: {
              id: "quiz-final",
              questionList: [
                {
                  type: "singleAnswer",
                  id: "q0",
                  instructions: "Une seule réponse possible",
                  question: "Quel label l’entreprise Henri Raffin a-t-elle obtenu en 2018 ?",
                  answerList: [
                    {
                      id: "q0-a0",
                      text: "Elue produit de l’année",
                      valid: false,
                    },
                    {
                      id: "q0-a1",
                      text: "Entreprise du patrimoine vivant",
                      valid: true,
                    },
                    {
                      id: "q0-a2",
                      text: "Entreprise du patrimoine gourmand",
                      valid: false,
                    },
                  ],
                },
                {
                  type: "singleAnswer",
                  id: "q1",
                  instructions: "Une seule réponse possible",
                  question:
                    "Quel type de boyaux utilisons-nous dans la production de nos saucissons à l’Ancienne, Supérieurs et de Savoie ?",
                  answerList: [
                    {
                      id: "q1-a0",
                      text: "Naturels",
                      valid: true,
                    },
                    {
                      id: "q1-a1",
                      text: "Collagéniques",
                      valid: false,
                    },
                    {
                      id: "q1-a2",
                      text: "Cellulosiques",
                      valid: false,
                    },
                    {
                      id: "q1-a3",
                      text: "Composés d'alginate de sodium, de cellulose ou de collagène",
                      valid: false,
                    },
                  ],
                },
                {
                  type: "singleAnswer",
                  id: "q2",
                  instructions: "Une seule réponse possible",
                  question: "Le saucisson nature représente ...% du marché du saucisson sec :",
                  answerList: [
                    {
                      id: "q2-a0",
                      text: "45 %",
                      valid: false,
                    },
                    {
                      id: "q2-a1",
                      text: "60 %",
                      valid: false,
                    },
                    {
                      id: "q2-a2",
                      text: "80 %",
                      valid: false,
                    },
                    {
                      id: "q2-a3",
                      text: "85 %",
                      valid: true,
                    },
                  ],
                },
                {
                  type: "singleAnswer",
                  id: "q3",
                  instructions: "Une seule réponse possible",
                  question: "D’où provient la fleur de nos saucissons secs ?",
                  answerList: [
                    {
                      id: "q3-a0",
                      text: "De la farine",
                      valid: false,
                    },
                    {
                      id: "q3-a1",
                      text: "Du talc",
                      valid: false,
                    },
                    {
                      id: "q3-a2",
                      text: "Du pénicilium naturel",
                      valid: true,
                    },
                    {
                      id: "q3-a3",
                      text: "Du geotrichum naturel",
                      valid: false,
                    },
                  ],
                },
                {
                  type: "singleAnswer",
                  id: "q4",
                  instructions: "Une seule réponse possible",
                  question:
                    "Quel est le pourcentage de gras de porc mis en œuvre en moyenne dans nos saucissons secs ?",
                  answerList: [
                    {
                      id: "q4-a0",
                      text: "20 %",
                      valid: true,
                    },
                    {
                      id: "q4-a1",
                      text: "40 %",
                      valid: false,
                    },
                    {
                      id: "q4-a2",
                      text: "60 %",
                      valid: false,
                    },
                  ],
                },
                {
                  type: "singleAnswer",
                  id: "q5",
                  instructions: "Une seule réponse possible",
                  question: "La viande de porc utilisée pour la production de nos diots est à …% d’origine française ?",
                  answerList: [
                    {
                      id: "q5-a0",
                      text: "50 %",
                      valid: false,
                    },
                    {
                      id: "q5-a1",
                      text: "75 %",
                      valid: false,
                    },
                    {
                      id: "q5-a2",
                      text: "90 %",
                      valid: false,
                    },
                    {
                      id: "q5-a3",
                      text: "100 %",
                      valid: true,
                    },
                  ],
                },
                {
                  id: "q6",
                  type: "multipleAnswer",
                  instructions: "Plusieurs réponses possibles",
                  question: "Comment peuvent-être dégustés nos diots ?",
                  answerList: [
                    {
                      id: "q6-a0",
                      text: "Au barbecue",
                      valid: true,
                    },
                    {
                      id: "q6-a1",
                      text: "A la poêle",
                      valid: true,
                    },
                    {
                      id: "q6-a2",
                      text: "En potée",
                      valid: true,
                    },
                    {
                      id: "q6-a3",
                      text: "Crus",
                      valid: false,
                    },
                  ],
                },
                {
                  type: "singleAnswer",
                  id: "q7",
                  instructions: "Une seule réponse possible",
                  question: "Quel est le pourcentage d'eau présent dans nos diots ?",
                  answerList: [
                    {
                      id: "q7-a0",
                      text: "Moins de 5%",
                      valid: true,
                    },
                    {
                      id: "q7-a1",
                      text: "6 %",
                      valid: false,
                    },
                    {
                      id: "q7-a2",
                      text: "8 %",
                      valid: false,
                    },
                    {
                      id: "q7-a3",
                      text: "10 %",
                      valid: false,
                    },
                  ],
                },
                {
                  type: "singleAnswer",
                  id: "q8",
                  instructions: "Une seule réponse possible",
                  question: "Quel jambon fabriquons-nous ?",
                  answerList: [
                    {
                      id: "q8-a0",
                      text: "Le Jambon Cuit de Savoie ",
                      valid: false,
                    },
                    {
                      id: "q8-a1",
                      text: "Le Jambon Cru de Savoie ",
                      valid: false,
                    },
                    {
                      id: "q8-a2",
                      text: "Le Jambon Sec Supérieur de Savoie",
                      valid: true,
                    },
                    {
                      id: "q8-a3",
                      text: "Le Jambon Cru Supérieur de Savoie",
                      valid: false,
                    },
                  ],
                },
                {
                  type: "multipleAnswer",
                  id: "q9",
                  instructions: "Plusieurs réponses possibles",
                  question: "Quel est le mode de fabrication de nos jambons ? ",
                  answerList: [
                    {
                      id: "q9-a0",
                      text: "Artisanal",
                      valid: true,
                    },
                    {
                      id: "q9-a1",
                      text: "Industriel",
                      valid: false,
                    },
                    {
                      id: "q9-a2",
                      text: "Salage par saumure",
                      valid: false,
                    },
                    {
                      id: "q9-a3",
                      text: "Salage au sel sec",
                      valid: true,
                    },
                  ],
                },
              ],
            },
          },
          {
            id: "resultat-quiz-final",
            relatedQuiz: "quiz-final",
            embedNavigation: true,
            shouldSendDataTo: "https://register-stats-server.upandgo.fr/user-scorm",
            background: { type: "image", url: asset("/assets/content/background/bg-activity-yellow.jpg") },
            type: "quizResult",
            scoreExpression: "percentage",
            fail: {
              text: "Dommage",
            },
            success: {
              text: "Félicitations ! Vous avez bien suivi la formation Henri Raffin.",
              certificate: {
                backgroundImage: asset("/assets/content/background/bg-certificate.jpg"),
                companyLogo: asset("/assets/content/logo-henri-raffin.png"),
                formationLogo: asset("/assets/content/logo-henri-raffin.png"),
                formationName: "Plus de 80 ans de savoir-faire artisanal",
                primaryColor: "#C7342A",
                secondaryColor: "#000000",
              },
            },
          },
        ],
        primaryColor: "#FFFFFF",
        secondaryColor: "#59372F",
      },
    ],
  };
};

export default getContent("fr");
